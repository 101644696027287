import http from './http'
import config from '@/utils/config'
//
/**
 *  @parms resquest 请求地址
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

const resquest = `/backEnd/api/${config.supplyCode}/UserRole`
function getUserMenuPermission(params) {
  return http.get(`${resquest}/getUserMenuPermission`, params)
}

function getUserRolePages(params) {
  return http.post(`${resquest}/getUserRolePages`, params)
}
function addUserRole(params) {
  return http.post(`${resquest}/addUserRole`, params)
}

function getUserRoleAssignedPermission(params) {
  return http.get(`${resquest}/getUserRoleAssignedPermission`, params)
}
function getUserRoleUnassignedPermission(params) {
  return http.get(`${resquest}/getUserRoleUnassignedPermission`, params)
}
function grantPermission(params) {
  return http.post(`${resquest}/grantPermission`, params)
}

function disableUserRole(params) {
    return http.put(`${resquest}/disableUserRole`, params)
  }

  function enableUserRole(params) {
    return http.put(`${resquest}/enableUserRole`, params)
  }

const user = `/backEnd/api/${config.supplyCode}/user`
function getUserPages(params) {
  return http.post(`${user}/getUserPages`, params)
}

export {
  getUserMenuPermission,
  getUserPages,
  addUserRole,
  getUserRolePages,
  getUserRoleAssignedPermission,
  getUserRoleUnassignedPermission,
  grantPermission,
  disableUserRole,
  enableUserRole
}
