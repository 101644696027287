var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-content", class: [{ isCollapse: _vm.isCollapse }] },
    [
      _c(
        "div",
        {
          staticClass: "collapse",
          attrs: {
            title: _vm.isCollapse ? "展开" : "收起",
            onselectstart: "return false",
          },
          on: { click: _vm.onCollapse },
        },
        [
          !_vm.isCollapse
            ? _c("svg-icon", {
                attrs: {
                  iconClass: "menu-close",
                  className: "icon",
                  title: "收起",
                },
              })
            : _c("svg-icon", {
                attrs: {
                  iconClass: "menu-open",
                  className: "icon",
                  title: "展开",
                },
              }),
          _c("span", { staticClass: "nav-title" }, [_vm._v("全部应用")]),
        ],
        1
      ),
      !_vm.isCollapse
        ? _c(
            "div",
            { staticClass: "menu-open" },
            _vm._l(_vm.menuLists, function (item, $index) {
              return _c(
                "div",
                {
                  key: $index,
                  staticClass: "panel",
                  class: { active: _vm.acitvePath.indexOf(item.path) > -1 },
                  attrs: { onselectstart: "return false" },
                },
                [
                  item.children.length > 1
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "nav-item",
                            on: {
                              click: function ($event) {
                                return _vm.setOpenIndex(item.path)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "nav-mark" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    iconClass: item.meta.icon,
                                    className: "icon",
                                  },
                                }),
                                _c("span", { staticClass: "nav-title" }, [
                                  _vm._v(_vm._s(item.meta.title)),
                                ]),
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "el-icon-arrow-right down",
                              class: { deg: _vm.isOpenItem === $index },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "child-content",
                            class: { "is-open": _vm.isOpenItem === $index },
                          },
                          _vm._l(item.children, function (child, key) {
                            return _c(
                              "div",
                              {
                                key: key,
                                staticClass: "child-item",
                                class: {
                                  active:
                                    _vm.acitvePath.indexOf(child.path) > -1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goto(
                                      `${item.path}/${child.path}`
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "child-item-title" },
                                  [_vm._v(_vm._s(child.meta.title))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass: "nav-item",
                            on: {
                              click: function ($event) {
                                return _vm.goto(
                                  `${item.path}${
                                    item.children[0]?.path
                                      ? `/${item.children[0]?.path}`
                                      : ""
                                  }`
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "nav-mark" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    iconClass: item.meta.icon,
                                    className: "icon",
                                  },
                                }),
                                _c("span", { staticClass: "nav-title" }, [
                                  _vm._v(_vm._s(item.meta.title)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                ],
                2
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "menu-close" },
            _vm._l(_vm.menuLists, function (item, $index) {
              return _c("div", { key: $index, staticClass: "panel" }, [
                _c(
                  "div",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.acitvePath.indexOf(item.path) > -1 },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { iconClass: item.meta.icon, className: "icon" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "child-content" },
                  [
                    _c("div", { staticClass: "nav-close-title" }, [
                      _vm._v(_vm._s(item.meta.title)),
                    ]),
                    _vm._l(item.children, function (child, key) {
                      return [
                        item.children.length > 1
                          ? [
                              _c(
                                "p",
                                {
                                  key: key,
                                  staticClass: "sub-menu-item",
                                  class: {
                                    active:
                                      _vm.acitvePath.indexOf(child.path) > -1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goto(
                                        `${item.path}/${child.path}`
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(child.meta.title) + " ")]
                              ),
                            ]
                          : [
                              _c(
                                "p",
                                {
                                  key: key,
                                  staticClass: "sub-menu-item",
                                  class: {
                                    active:
                                      _vm.acitvePath.indexOf(item.path) > -1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goto(`${item.path}`)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(child.meta.title) + " ")]
                              ),
                            ],
                      ]
                    }),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }