
const SET_ADDRESS = 'SET_ADDRESS'
const SET_USERTYPE = 'SET_USERTYPE'
import { getAddressDropdown } from '@/api/basic'
import { getUserType } from '@/api/system'
const basiData = {
  state: {
    addressDropOption: [],
    userTypeOptions: null,
    sysParams: null
  },
  mutations: {
    [SET_ADDRESS](state, data) {
     state.addressDropOption = data
    },
    [SET_USERTYPE](state, data) {
        const arr = []
        data && data.map(item => {
            arr.push({
                value: item.value,
                label: item.name
            })
        })
     state.userTypeOptions = arr
    }
  },
  actions: {
    getAddress({ commit }) {
      return new Promise((resolve, reject) => {
        getAddressDropdown()
          .then((res) => {
            commit(SET_ADDRESS, res)
            resolve(res)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getUserTypeOption({ commit }) {
        return new Promise((resolve, reject) => {
            getUserType()
            .then((res) => {
              commit(SET_USERTYPE, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              return reject(err)
            })
        })
    }
  },
  getters: {
    addressDropOption: state => state.addressDropOption,
    userTypeOptions: state => state.userTypeOptions
  }
}

export default basiData
