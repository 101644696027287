<template>
  <el-drawer
    :visible.sync="$store.state.system.upcomeDrawer"
    :append-to-body="true"
    :size="size"
    :modal="false"
    :wrapperClosable="true"
    :before-close="handleClose"
  >
    <template slot="title">
      <span>
        <span
          :class="{ active: !isActive }"
          class="read"
          @click.stop="handleClick(0)"
        >待办({{ msgData.unread }})</span
        >
        <span
          :class="{ active: isActive }"
          class="read"
          @click.stop="handleClick(1)"
        >已办({{ msgData.read }})</span
        >
      </span>
    </template>
    <div class="view-container">
      <div class="opt">
        <el-select
          v-model="type"
          style="width: 120px"
          placeholder="请选择"
          clearable
          @change="onChange"
        >
          <el-option
            v-for="item in config.AgencyTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div style="display: flex;">
          <div class="search">
            <el-input
              v-model="searchVal"
              placeholder="请输入内容搜索"
              class="input-search"
              @keydown.enter.native="search"
            ></el-input>
            <div class="btn-search" @click="search">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <BaseFilter
            :isActive="filterActive"
            :width="452"
            @sendFilter="sendFilter"
            @reset="reset"
          >
            <div class="filter">
              <el-form ref="form" :model="filterList" label-width="56px">
                <el-form-item label="客户">
                  <el-select
                    v-model="filterList.companyIds"
                    style="width: 320px;"
                    placeholder="请选择类型"
                    multiple
                  >
                    <el-option
                      v-for="item in companyDrapdown"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="优先级">
                  <el-select
                    v-model="filterList.priority"
                    placeholder="请选择"
                    style="width: 320px"
                  >
                    <el-option label="非常紧急" value="0"></el-option>
                    <el-option label="紧急" value="1"></el-option>
                    <el-option label="一般" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </BaseFilter>
        </div>
      </div>
      <div v-loading="loading" class="contanier scrollbar">
        <div v-for="item of lists" :key="item.id" class="panel">
          <div class="lable">
            <p>
              <img src="@/assets/images/loyout/user.png" />
              客户：{{ item.companyId | filterCompany }}
              <span class="msg-type">{{ item.statusName }}</span>
            </p>
            <el-link
              v-if="!isActive"
              :underline="false"
              type="primary"
              @click="openUrl(item)"
            >查看详情</el-link
            >
          </div>
          <div class="lable des">
            {{ item.description }}
          </div>
          <div class="lable">创建：{{ item.createdOn ? item.createdOn.slice(0, item.createdOn.length - 3) : '' }}</div>
          <div class="level" :class="`level-${item.priority}`">
            {{ item.priority }}
          </div>
        </div>
      </div>
      <div class="pager">
        <el-pagination
          :current-page="pagerConfig.pageIndex"
          :page-size="20"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-drawer>
</template>
<script>
let that
import { getSchedulePages, workBenchOverview } from '@/api/home'
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { getRouteByCode } from '@/utils/common'
import { mapGetters } from 'vuex'
import config from '@/utils/config'
export default {
  name: 'UpcomeDrawer',
  components: { BaseFilter },
  filters: {
    filterCompany(text) {
      const item = that.companyDrapdown.find((a) => a.value === text)
      if (item) {
        return item.label
      }
      return ''
    }
  },
  props: {
    size: {
      type: Number,
      default: 520
    }
  },
  data() {
    return {
      config,
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      filterList: {
        companyIds: '',
        priority: ''
      },
      msgData: {},
      isActive: 0,
      filterActive: false,
      total: 0,
      lists: [],
      type: '',
      searchVal: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  watch: {
    '$store.state.system.upcomeDrawer'(val) {
      if (val) {
        this.getList(this.pagerConfig)
        this.getSumTotal()
        if (!this.companyDrapdown.length) {
          this.$store.dispatch('getCompanyDrapdown')
        }
      }
    }
  },
  mounted() {
    that = this
  },
  methods: {
    async getList(param) {
      try {
        this.loading = true
        param = {
          ...param,
          queryParam: {
            status: this.isActive,
            ...this.formatterFilter()
          }
        }
        const res = await getSchedulePages(param)
        if (res.success) {
          this.loading = false
          this.lists = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getSumTotal() {
      try {
        const res = await workBenchOverview()
        if (res.success) {
          const msgData = {
            unread: res.data.schedule.find((a) => a.type === 2).data.value,
            read: res.data.schedule.find((a) => a.type === 3).data.value
          }
          this.msgData = msgData
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCurrentChange(pageIndex) {
      const param = {
        pageIndex,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.getList(param)
    },
    handleClose() {
      this.$store.commit('CHANGE_UPCOME_DRAWER', false)
    },
    handleClick(status) {
      this.isActive = status
      this.reset()
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
    },
    sendFilter() {
      if (
        this.filterList.priority !== '' ||
        this.filterList.companyIds.length > 0
      ) {
        this.filterActive = true
      } else {
        this.filterActive = false
      }
      const pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = pager
      this.getList(pager)
    },
    reset() {
      this.filterList = {
        priority: '',
        companyIds: ''
      }
      this.filterActive = false
    },
    formatterFilter() {
      const { companyIds, priority } = this.filterList
      return {
        search: this.searchVal || undefined,
        priority: priority !== '' ? priority : undefined,
        type: this.type !== '' ? this.type : undefined,
        companyIds: companyIds.length > 0 ? companyIds : undefined
      }
    },
    onChange() {
      const pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = pager
      this.getList(pager)
    },
    openUrl({ menuCode, id: scheduleId, queryJson, type }) {
      const path = getRouteByCode(menuCode)
      // console.log(path, menuCode, btnCode)
      const params = { menuCode, scheduleId, queryJson }
      this.$store.commit('SET_CONNNET', params)
      if (path !== this.$route.fullPath) {
        this.$router.push(path)
      }
      this.handleClose()
      if (type === 1) {
        this.$store.dispatch('readConnnet')
      }
    },
    search() {
      const pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = pager
      this.getList(pager)
    }
  }
}
</script>
<style lang="less" scoped>
.read {
  color: #999999;
  cursor: pointer;
  & + .read {
    margin-left: 30px;
  }
}
.active {
  color: #2395f3;
}
.view-container {
  height: 100%;
  padding: 0 20px;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:deep(.el-input__inner) {
      height: 30px;
    }
    &:deep(.el-input__suffix) {
      top: 5px;
    }
    &:deep(.el-input__icon) {
      line-height: inherit;
    }
    &:deep(.el-input__suffix-inner) {
      display: inline-block;
    }
    .search {
      position: relative;
      display: flex;
      width: 160px;
      .input-search {
        width: 126px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  .contanier {
    width: 100%;
    height: calc(100% - 220px);
    overflow-y: auto;
  }
  .pager {
    padding-top: 40px;
    float: right;
  }
  .panel {
    width: 100%;
    height: 137px;
    background: #fbfbfb;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    position: relative;
    overflow: hidden;
    .lable {
      margin-top: 20px;
      padding: 0 20px 0 34px;
      width: calc(100% - 54px);
      height: 19px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 19px;
      img {
        margin-right: 10px;
      }
    }
    .msg-type {
      height: 16px;
      padding: 1px 5px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      background: #f2f2f2;
      border-radius: 4px 4px 4px 4px;
      line-height: 19px;
      margin-left: 10px;
    }
    .des {
      color: #666666;
    }
    .level {
      width: 24px;
      height: 20px;
      border-radius: 6px 0px 6px 0px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      position: absolute;
      top: 0;
    }
    .level-T1 {
      background: rgba(254, 69, 61, 0.2);
      color: #fe453d;
    }
    .level-T2 {
      background: rgba(157, 103, 254, 0.2);
      color: #9d67fe;
    }
    .level-T3 {
      background: rgba(255, 116, 68, 0.2);
      color: rgba(255, 116, 68, 1);
    }
    .level-T4 {
      background: rgba(53, 186, 131, 0.2);
      color: rgba(53, 186, 131, 1);
    }
    .level-T5 {
      background: rgba(35, 149, 243, 0.2);
      color: rgba(35, 149, 243, 1);
    }
    & + .panel {
      margin-top: 10px;
    }
  }
}
:deep(.el-drawer__header) {
  border-bottom: 1px solid #f7f7f7;
  margin-bottom: 0;
  padding-bottom: 18px;
  padding-top: 18px;
}
:deep(.el-drawer__body) {
  height: 100%;
}
</style>
