const SET_LOOP_ITEM = 'set_loop_item'
const REDUCE_LOOP_ITEM = 'reduce_loop_item'
const SET_EMPTY_ITEM = 'set_empty_item'
const loop = {
    state: {
        caches: []
    },
    mutations: {
        [SET_LOOP_ITEM](state, arr) {
            state.caches.push(arr)
            if (state.caches.length >= 10) {
                state.caches.shift()
            }
        },
        [REDUCE_LOOP_ITEM](state) {
            state.caches.pop()
        },
        [SET_EMPTY_ITEM](state) {
            state.caches = []
        }
    }
    // getters: {
    //     caches: state => {
    //         if (state.caches.length === 0) {
    //             return []
    //         } else {
    //             return state.caches[state.caches.length - 1]
    //         }
    //     }
    // }
}
export default loop
