const SET_BILL_REMARK_LIST = 'SET_BILL_REMARK_LIST'
const SET_DO_TPL_LIST = 'SET_DO_TPL_LIST'
const SET_AIR_TRANSPORT = 'SET_AIR_TRANSPORT'
import { getMBLFollowUpTerminology, GetDoTemplate } from '@/api/bill'
import { transportDrapDown } from '@/api/basic'
const bill = {
  state: {
    airTransportOptions: [],
    billRemarkTypeList: [], // 提单更进术语
    doTplList: [] // ggt模板
  },
  mutations: {
    [SET_BILL_REMARK_LIST](state, remarkTypeList) {
      state.billRemarkTypeList = remarkTypeList
    },
    [SET_DO_TPL_LIST](state, doTplList) {
      state.doTplList = doTplList
    },
    [SET_AIR_TRANSPORT](state, data) {
      state.airTransportOptions = data
    }
  },
  actions: {
    getBillRemarkList({ commit }) {
      return new Promise((resolve, reject) => {
        getMBLFollowUpTerminology()
          .then((res) => {
            commit(SET_BILL_REMARK_LIST, res.data)
            resolve(res.data)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getDoTplList({ commit }) {
      return new Promise((resolve, reject) => {
        GetDoTemplate()
          .then((res) => {
            commit(SET_DO_TPL_LIST, res.data)
            resolve(res.data)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getAirTransportOptions({ commit }) {
      return new Promise((resolve, reject) => {
        transportDrapDown({
          type: 0
        })
          .then((res) => {
            if (res.success) {
              commit(SET_AIR_TRANSPORT, res.data)
            }
            resolve(res)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    }
  },
  getters: {
    // billShipTabNumber: (state) => state.billShipTabNumber,
    airTransportOptions: (state) => state.airTransportOptions,
    billRemarkTypeList: (state) => state.billRemarkTypeList,
    doTplList: (state) => state.doTplList
  }
}
export default bill
