var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-content" },
    [
      _vm._m(0),
      _c("div", { staticClass: "opt" }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              {
                staticClass: "item",
                attrs: { title: "查看使用文档" },
                on: { click: _vm.doc },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/loyout/doc.png") },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "item",
                attrs: { title: "待办" },
                on: { click: _vm.showUpComing },
              },
              [
                _vm.isNewUpcome
                  ? _c("span", { staticClass: "new-info" })
                  : _vm._e(),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/loyout/upcoming.png"),
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "item",
                attrs: { title: "消息" },
                on: { click: _vm.showMessage },
              },
              [
                _vm.isNewMeg
                  ? _c("span", { staticClass: "new-info" })
                  : _vm._e(),
                _c("img", {
                  attrs: { src: require("@/assets/images/loyout/message.png") },
                }),
              ]
            ),
            _c(
              "el-popover",
              {
                attrs: {
                  "popper-class": "userPop",
                  placement: "bottom-end",
                  offset: 20,
                  trigger: "hover",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "user-menu",
                    on: {
                      mouseenter: function ($event) {
                        _vm.topArrow = true
                      },
                      mouseleave: function ($event) {
                        _vm.topArrow = false
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "user-header" }, [
                      _c("div", { staticClass: "left flex_c_c" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/loyout/portrait.png"),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.userInfo?.userName)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "label" },
                          [
                            _vm._l(_vm.userInfo?.userRoleName, function (item) {
                              return _c(
                                "span",
                                { key: item, staticClass: "role flex_c_c" },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            _c("span", { staticClass: "type flex_c_c" }, [
                              _vm._v(_vm._s(_vm.userInfo?.type)),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("ul", { staticClass: "user-body" }, [
                      _c("li", { staticClass: "info" }, [
                        _c("span", [_vm._v("编码")]),
                        _c("span", [_vm._v(_vm._s(_vm.userInfo?.code))]),
                      ]),
                      _c("li", { staticClass: "info" }, [
                        _c("span", [_vm._v("电话")]),
                        _c("span", [_vm._v(_vm._s(_vm.userInfo?.phone))]),
                      ]),
                      _c("li", { staticClass: "info" }, [
                        _c("span", [_vm._v("邮箱")]),
                        _c("span", [_vm._v(_vm._s(_vm.userInfo?.email))]),
                      ]),
                      _c("li", { staticClass: "info" }, [
                        _c("span", [_vm._v("地点")]),
                        _c("span", [_vm._v(_vm._s(_vm.country))]),
                      ]),
                      _c("li", { staticClass: "info" }, [
                        _c("span", [_vm._v("状态")]),
                        _c("span", [
                          _c("i", {
                            staticClass: "mark",
                            class: [_vm.userInfo?.isActived ? "green" : "red"],
                          }),
                          _vm._v(
                            _vm._s(_vm.userInfo?.isActived ? "使用中" : "停用")
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "user-footer", on: { click: _vm.exit } },
                      [_vm._v("退出登录")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "user",
                    attrs: { slot: "reference" },
                    on: {
                      mouseenter: _vm.enterUser,
                      mouseleave: _vm.leaveUser,
                    },
                    slot: "reference",
                  },
                  [
                    _c("div", { staticClass: "avatar" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/loyout/avatar.png"),
                        },
                      }),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-caret-bottom icon",
                      class: { deg: _vm.topArrow },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("upcome-drawer"),
      _c("message-drawer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "omsLogo",
        attrs: {
          src: require("@/assets/images/loyout/logo1.png"),
          width: "134px",
          height: "23px",
        },
      }),
      _c("div", { staticClass: "line" }),
      _c("img", {
        staticClass: "ccsLogo",
        attrs: {
          src: require("@/assets/images/loyout/logo2.png"),
          width: "44px",
          height: "22px",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }