var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "logobg",
      attrs: { src: require("@/assets/images/logobg.png") },
    }),
    _c(
      "div",
      { class: ["form", _vm.showIcon === "show" ? "showIcon" : ""] },
      [_c("router-view")],
      1
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "copyright" }, [
      _vm._v(" 版权所有 © 2023 FEEPOWER "),
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
        [_vm._v("粤ICP备2021066614号-3")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }