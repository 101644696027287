<template>
  <div class="alive-nav-content">
    <div
      v-for="tag in visitedTabsView"
      :key="tag.path"
      class="tags-view-item"
      @click="go(tag.path)"
    >
      <div class="tag flex_c_c" :class="[{ active: isActive(tag) }]">
        <span>{{ tag.name }}</span>
        <span class="closebox flex_c_c" @click.stop="handleClose(tag)">
          <svg-icon
            iconClass="close"
            className="icon"
          />
        </span>
      </div>
    </div>
    <div class="reload flex_c_c" @click="reload">
      <img title="刷新" src="@/assets/images/loyout/reload.png" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['visitedTabsView'])
  },
  watch: {
    $route() {
      this.addTabsView()
    }
  },
  created() {
    console.log('this.$route.path', this.$route.path)
    this.addTabsView()
  },
  methods: {
    ...mapActions(['addVisitedTabsView', 'delVisitedTabsView']),
    addTabsView() {
      if (this.visitedTabsView.length > 9) {
        // this.$message.warning('页签最多10个')
        this.handleClose(this.visitedTabsView[0])
      }
      const route = this.generateRoute()
      console.log('route', route)
      if (!route) {
        return false
      }
      this.addVisitedTabsView(this.generateRoute())
    },
    generateRoute() {
      if (this.$route.meta.title) {
        return this.$route
      }
      return false
    },
    isActive(route) {
      return route.path === this.$route.path || route.name === this.$route.name
    },
    go(path) {
      const query = this.$route.query
      if (query.menuCode) {
        this.$confirm('当前文档未保存，是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          iconClass: 'warning',
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          center: true
        })
          .then(() => {
            this.$router.push(path)
          })
          .catch(() => {})
      } else {
        this.$router.push(path)
      }
    },
    handleClose(tag) {
      this.delVisitedTabsView(tag).then((tags) => {
        // 如果关闭的是当前显示的页面，就去到前一个 tab-view 页面
        if (this.isActive(tag)) {
          const lastTag = tags.slice(-1)[0]
          // 前一个 tab-view 页面存在，就跳；不存在就到首页
          if (lastTag) {
            this.$router.push(lastTag.path)
          } else {
            this.$message.warning('最后一个标签不能删除')
          }
        } else {
          this.removeKeepAliveCacheForVueInstance(this, tag.componentName)
        }
      })
    },
    removeKeepAliveCacheForVueInstance(vueInstance, componentName) {
      const parentDom = vueInstance.$parent
      const childList = parentDom.$children
      childList.forEach(vnode => {
        if (vnode.$vnode.tag.indexOf(componentName) > -1) {
          const key = vnode.$vnode.parent.componentInstance.keyToCache
          const cache = vnode.$vnode.parent.componentInstance.cache
          const keys = vnode.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              vnode.$destroy()
              delete cache[key]
              const index = keys.indexOf(key)
              if (index > -1) {
                keys.splice(index, 1)
              }
            }
        }
      })
      this.$store.commit('SET_CREATE_TAB', { type: 'order', bool: false })
    },
    reload() {
      const parentDom = this.$parent
      const childList = parentDom.$children
      childList.forEach(vnode => {
        if (vnode.$vnode.tag.indexOf(this.$route.name) > -1) {
          if (vnode.$vnode.componentInstance.refresh) {
            // this.removeKeepAliveCacheForVueInstance(this, this.$route.meta.componentName)
            vnode.$vnode.componentInstance.refresh()
            this.$nextTick(() => {
              this.$message.success('当前页面数据已刷新')
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.alive-nav-content {
  width: 100%;
  height: 32px;
  margin: 6px 0;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  .tags-view-item {
    & + .tags-view-item {
      margin-left: 10px;
    }
  }
  .reload {
    position: absolute;
    z-index: 999;
    width: 30px;
    height: 30px;
    background: #F7F7F7;
    border-radius: 15px;
    right: 20px;
    cursor: pointer;
  }
  .tag {
    height: 30px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    box-sizing: border-box;
    cursor: pointer;
    .closebox {
      height: 100%;
      padding: 0 8px 0 8px;
      box-sizing: border-box;
    }
    .icon {
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #2395f3;
      }
    }
  }
  .active {
    color: #2395f3;
  }
}
</style>
