/** **   http.js   ****/
// 导入封装好的axios实例
import request from './request'
import axios from 'axios'

const http = {
    /**
     * methods: 请求
     * @param url 请求地址
     * @param params 请求参数
     */
    get(url, params) {
        const config = {
            method: 'get',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },
    post(url, params) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) config.data = params
        return request(config)
    },
    put(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },
    delete(url, params) {
        const config = {
            method: 'delete',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },
    upload(url, param, fn) {
        return axios({
            url: url,
            method: 'post',
            data: param,
            onUploadProgress: fn,
            contentType: false,
            processData: false,
            headers: {
                'Content-Type': undefined
            }
        })
    },
    download(url, param, type = 'get') {
        return axios({
            url: url,
            method: type,
            data: JSON.stringify(param),
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        })
    }
}
// 导出
export default http
