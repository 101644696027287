<template>
  <div class="resister-content">
    <Tab :tabData="tabData" :active="active"> </Tab>
    <div class="form">
      <el-form ref="form" :model="form">
        <el-form-item prop="number" style="position: relative">
          <el-input
            v-model="form.number"
            type="text"
            :placeholder="`${form.sendType ? '请输入邮箱' : '请输入手机号'}`"
          >
            <el-select slot="prepend" v-model="form.sendType" class="sendType" placeholder="请选择">
              <el-option label="电话" :value="0"></el-option>
              <el-option label="邮箱" :value="1"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="passCode" style="position: relative;">
          <div class="ecode">
            <el-input
              v-model="form.passCode"
              type="text"
              maxlength="20"
              style="width: 260px"
              placeholder="请输入验证码"
              oninput="value=value.replace(/[^0-9]/g,'').trim()"
            >
            </el-input>
            <el-button
              :disabled="disabled"
              class="btn"
              @click="sendECode"
            >{{ sendCode }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item prop="password" style="position: relative">
          <el-input
            v-model="form.password"
            :type="showEye1 ? 'text' : 'password'"
            placeholder="请输入新密码"
          >
            <span slot="suffix" class="suffixEye" @click="showEye1 = !showEye1">
              <svg-icon v-show="!showEye1" className="openEye" iconClass="openEye" />
              <svg-icon v-show="showEye1" className="closeEye" iconClass="closeEye" />
            </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="newpassword" style="position: relative">
          <el-input
            v-model="form.newpassword"
            :type="showEye2 ? 'text' : 'password'"
            placeholder="请重新输入密码"
          >
            <span slot="suffix" class="suffixEye" @click="showEye2 = !showEye2">
              <svg-icon v-show="!showEye2" className="openEye" iconClass="openEye" />
              <svg-icon v-show="showEye2" className="closeEye" iconClass="closeEye" />
            </span>
          </el-input>
        </el-form-item>
        <el-button
          class="registe-btn"
          type="primary"
          :loading="loading"
          @click="resetPass"
        >完成</el-button
        >
        <p class="goLogin" @click="goLogin">返回登录</p>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tab from './components/Tab.vue'
import { mapGetters } from 'vuex'
import { sendPassCode, forgotPassword } from '@/api/system'
import { getKey } from '@/utils/common'
import { isValidPhone, isValidEmail, isValidPwd } from '@/utils/validate'
import { Encrypt } from '@/utils/secret'
export default {
  name: 'Resister',
  components: { Tab },
  data() {
    return {
      tabData: ['重置密码'],
      active: 1,
      form: {
        password: '',
        newpassword: '',
        number: '',
        sendType: 0,
        passCode: ''
      },
      sendCode: '获取验证码',
      disabled: false,
      loading: false,
      key: '',
      fingerId: null,
      showEye1: false,
      showEye2: false
    }
  },
  computed: {
    ...mapGetters(['sign'])
  },
  mounted() {
  },
  methods: {
    async resetPass() {
      if (
        !(
          this.form.password &&
          this.form.newpassword &&
          this.form.number &&
          this.form.passCode
        )
      ) {
        this.$message.error(`未填写完整信息`)
        return
      }
      if (this.form.password !== this.form.newpassword) {
        this.$message.error('密码不一致')
        return
      }
      if (!isValidPwd(this.form.password)) {
        this.$message.error(
          `密码长度6-32个字符，字母/数字/下划线组成(必须含有字母和数字)`
        )
        return
      }
      //
      const key = await getKey()
      this.fingerId = key
      await this.$store.dispatch('getSign', key)
      const obj = {
        number: this.form.number,
        password: Encrypt(this.form.password, this.sign),
        passCode: this.form.passCode,
        fingerId: this.fingerId,
        sendType: this.form.sendType
      }
      forgotPassword(obj).then((res) => {
        if (res.success) {
          this.$message.success(`重置成功! `)
          this.$router.push('/login')
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    sendECode() {
      if (!this.form.number) {
        this.$message.error(`未填写${this.form.sendType ? '邮箱' : '手机号'}`)
        return
      }
      if (!isValidPhone(this.form.number) && !this.form.sendType) {
        this.$message.error('请填写正确格式的手机')
        return
      } else if (!isValidEmail(this.form.number) && this.form.sendType) {
        this.$message.error('请填写正确格式的邮箱')
        return
      }
      const param = {
        number: this.form.number,
        sendType: this.form.sendType,
        passCodeType: 2 // 0: 登录 1：注册 2：忘记密码 3：核验 4 修改
      }
      sendPassCode(param).then((res) => {
        if (res.success) {
          this.disabled = true
          let time = 60
          const timer = setInterval(() => {
            if (time > 0) {
              this.sendCode = `${time}秒重发`
              time--
            } else {
              clearInterval(timer)
              this.disabled = false
              this.sendCode = '发送验证码'
              time = 60
            }
          }, 1000)
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    goLogin() {
        this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.resister-content {
  width: calc(100% - 100px);
  height: calc(100% - 80px);
  margin: 40px 50px;
  .form {
    width: 100%;
    height: auto;
    margin-top: 39px;
    /deep/ .el-input__suffix {
      display: flex;
      align-items: center;
      width: 35px;
      padding-right: 15px;
      right: 0;
      box-sizing: border-box;
    }
    .suffixEye {
      font-size: 20px;
      cursor: pointer;
    }
    .ecode {
      display: flex;
      justify-content: space-between;
    }
    .btn {
      width: 124px;
      height: 56px;
      background: #F7F7F7;
      font-size: 16px;
      font-weight: 400;
      color: #2395F3;
      border: 0;
      padding: 0;
    }
    .registe-btn {
      width: 100%;
      height: 56px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10px;
    }
    .goLogin {
      width: auto;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      margin: 38px auto 0;
      text-align: center;
      cursor: pointer;
      &:hover {
        color:  #2395f3;
      }
    }
  }
  /deep/.el-select .el-input {
    width: 100px;
  }
  /deep/ .el-input__inner, /deep/ .el-select .el-input__inner {
    height: 56px;
    background: #F7F7F7;
    border: 0;
    color: #333333;
  }
  /deep/ .el-input-group__prepend {
    background: #F7F7F7;
    border: 0;
    &::after {
      content: '';
      position: absolute;
      height: 16px;
      border-left: 1px solid #B8C3DE;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  /deep/ .el-input__inner::-webkit-input-placeholder{
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #CDCDCD;
  }
  /deep/ .sendType {
    color:#333333;
    font-size: 16px;
  }
}
</style>
