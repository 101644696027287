<template>
  <div class="content">
    <Tab :tabData="tabData" :active="active" @tabChange="tabChange"></Tab>
    <div class="form">
      <el-form v-if="active === 1" ref="loginForm" :rules="rules" :model="form">
        <el-form-item prop="username" style="position: relative">
          <el-input
            v-model="form.username"
            type="text"
            placeholder="请输入手机号或邮箱"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password1">
          <el-input
            v-model="form.password1"
            :type="showEye ? 'text' : 'password'"
            maxlength="20"
            placeholder="请输入密码"
            @keyup.enter.native="onLogin('loginForm')"
          >
            <span slot="suffix" class="suffixEye" @click="showEye = !showEye">
              <svg-icon v-show="!showEye" className="openEye" iconClass="openEye" />
              <svg-icon v-show="showEye" className="closeEye" iconClass="closeEye" />
            </span>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex">
            <div style="text-align: initial">
              <el-checkbox
                v-model="remember"
                :label="login.remember"
              ></el-checkbox>
            </div>
            <span style="cursor: pointer;" @click="resetPassword">忘记密码</span>
          </div>
        </el-form-item>
      </el-form>
      <el-form v-if="active === 2" ref="loginForm" :rules="rules" :model="form">
        <el-form-item prop="username" style="position: relative">
          <el-input
            v-model="form.username"
            type="text"
            :placeholder="`${form.sendType ? '请输入邮箱' : '请输入手机号'}`"
          >
            <el-select slot="prepend" v-model="form.sendType" class="sendType" placeholder="请选择">
              <el-option label="电话" :value="0"></el-option>
              <el-option label="邮箱" :value="1"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <div class="ecode">
            <el-input
              v-model="form.password2"
              type="text"
              maxlength="20"
              style="width: 260px"
              placeholder="请输入验证码"
              oninput="value=value.replace(/[^0-9]/g,'').trim()"
            >
            </el-input>
            <el-button
              :disabled="disabled"
              class="btn2"
              style="margin-left: 16px; width: 124px"
              @click="sendECode"
            >{{ sendCode }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item>
          <div class="flex">
            <div style="text-align: initial">
              <el-checkbox
                v-model="remember"
                :label="login.remember"
              ></el-checkbox>
            </div>
            <span style="cursor: pointer;" @click="resetPassword">忘记密码</span>
          </div>
        </el-form-item>
      </el-form>
      <el-button
        class="btn"
        type="primary"
        :loading="loading"
        @click="onLogin('loginForm')"
      >{{ login.login }}</el-button
      >
      <div
        class="btn3 flex_c_c"
        type="primary"
      >
        <img src="@/assets/images/wechat.png">
        {{ login.wechatLogin }}
      </div>
    </div>
  </div>
</template>
<script>
import Tab from './components/Tab.vue'
import { sendPassCode, login } from '@/api/system'
import { getUserMenuPermission } from '@/api/setting'
import { getKey, firstPath, setCookie, getCookie, delCookie } from '@/utils/common'
import { Encrypt } from '@/utils/secret'
import { isValidPhone, isValidEmail } from '@/utils/validate'
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  components: { Tab },
  data() {
    return {
      form: {
        username: '',
        password1: '',
        password2: '',
        sendType: 0 // 0:电话 1:邮箱
      },
      login: {
        remember: '记住账号',
        login: '登录',
        wechatLogin: '微信登录'
      },
      rules: {
        username: [{ required: true, message: '请输入手机号或邮箱', trigger: 'blur' }],
        password1: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        password2: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      remember: false,
      loading: false,
      tabData: ['账号登录', '验证码登录'],
      sendCode: '发送验证码',
      disabled: false,
      active: 1,
      fingerId: null,
      showEye: false
    }
  },
  computed: {
    ...mapGetters(['sign'])
  },
  async mounted() {
    const key = await getKey()
    this.fingerId = key
    this.getuserpwd()
  },
  methods: {
    onLogin(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          await this.$store.dispatch('getSign', this.fingerId)
          this.loading = true
          const param = {
            userAccount: this.form.username,
            password:
              this.active === 1
                ? Encrypt(this.form.password1, this.sign)
                : this.form.password2,
            type: this.active === 1 ? 0 : this.form.sendType ? 3 : 1,
            fingerId: this.fingerId
          }
          login(param).then((res) => {
            if (res.success) {
              this.loading = false
              if (this.remember) {
                // 保存帐号到cookie，有效期7天
                setCookie('username', this.form.username, 7)
              } else {
                delCookie('username')
              }
              getUserMenuPermission().then(res => {
                if (!res.data || res.data.length === 0) {
                  this.$message.warning('用户暂未授权，请联系管理员')
                  return
                }
                this.$store.commit('ADD_INITROUTER', res.data)
                this.$store.dispatch('getUserInfo')
                this.$store.dispatch('addRouter').then(() => {
                  if (res.data.findIndex(a => a.nameCH === '首页') > -1) {
                    this.$router.push('/home/workbench')
                  } else {
                    const path = firstPath(res.data)
                    this.$router.push(path)
                  }
                })
              })
            } else {
              this.loading = false
              this.$message.error(res.errorMessage)
              this.$store.dispatch('getSign', this.fingerId)
            }
          })
        }
      })
    },
    getuserpwd() {
      // 如果缓存里面有记录，就直接获取登录
      if (getCookie('username') !== '') {
        this.form.username = getCookie('username')
        this.remember = true
      }
    },
    tabChange(index) {
      this.active = index
      this.$refs['loginForm'].resetFields()
    },
    register() {
      this.$router.push('/register')
    },
    sendECode() {
      if (!this.form.username) {
        this.$message.error(`未填写${this.form.sendType ? '邮箱' : '手机号'}`)
        return
      }
      if (!isValidPhone(this.form.username) && !this.form.sendType) {
        this.$message.error('请填写正确格式的手机')
        return
      } else if (!isValidEmail(this.form.username) && this.form.sendType) {
        this.$message.error('请填写正确格式的邮箱')
        return
      }
      const param = {
        number: this.form.username,
        passCodeType: 0, // 0: 登录 1：注册 2：忘记密码 3：核验 4 修改
        sendType: this.form.sendType
      }
      sendPassCode(param).then((res) => {
        if (res.success) {
          this.disabled = true
          let time = 60
          const timer = setInterval(() => {
            if (time > 0) {
              this.sendCode = `${time}秒重发`
              time--
            } else {
              clearInterval(timer)
              this.disabled = false
              this.sendCode = '发送验证码'
              time = 60
            }
          }, 1000)
        }
      })
    },
    resetPassword() {
      this.$router.push('/resetPassword')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 100px);
  height: calc(100% - 80px);
  margin: 40px 50px;
  overflow: hidden;
  .form {
    width: 100%;
    margin-top: 68px;
    height: auto;
    .el-form-item:last-child {
      margin: 0;
    }
    /deep/ .el-input__inner {
      background: #F7F7F7;
    }
    /deep/ .el-input__suffix {
      display: flex;
      align-items: center;
      width: 35px;
      padding-right: 15px;
      right: 0;
      box-sizing: border-box;
    }
    .suffixEye {
      font-size: 20px;
      cursor: pointer;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      /deep/.el-checkbox__label {
        font-weight: 400;
        color: #999999;
        line-height: 24px;
      }
    }
    .btn {
      width: 100%;
      height: 56px;
      background: #2395f3;
      border-radius: 4px;
      margin-top: 32px;
      opacity: 1;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }
    .btn3 {
      width: 400px;
      height: 56px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-top: 16px;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
    .no-account {
      width: 144px;
      height: 21px;
      font-size: 16px;
      font-weight: 400;
      color: #25396f;
      line-height: 24px;
      margin: 32px auto 0;
      cursor: pointer;
      &:hover {
        color:  #2395f3;
      }
    }
    .btn2 {
      width: 124px;
      height: 56px;
      background: #F7F7F7;
      font-size: 16px;
      font-weight: 400;
      color: #2395F3;
      border: 0;
      padding: 0;
    }
  }
  /deep/.el-select .el-input {
    width: 100px;
  }
  /deep/ .el-input__inner, /deep/ .el-select .el-input__inner {
    height: 56px;
    background: #F7F7F7;
    border: 0;
    color: #333333;
  }
  /deep/ .el-input-group__prepend {
    background: #F7F7F7;
    border: 0;
    &::after {
      content: '';
      position: absolute;
      height: 16px;
      border-left: 1px solid #B8C3DE;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  /deep/ .el-input__inner::-webkit-input-placeholder{
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #CDCDCD;
  }
  /deep/ .sendType {
    color:#333333;
    font-size: 16px;
  }
}
</style>
