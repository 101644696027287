var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.svgClass,
      attrs: { "aria-hidden": "true" },
      on: { click: _vm.handleClick },
    },
    [
      _c("title", [_vm._v(_vm._s(_vm.title))]),
      _c("use", { attrs: { "xlink:href": _vm.iconName } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }