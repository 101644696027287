// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/loyout/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container[data-v-2c0a6f1f] {\n  width: 100%;\n  height: 100%;\n}\n.container .header[data-v-2c0a6f1f] {\n  width: 100%;\n  height: 42px;\n  background: #2395f3;\n  border-radius: 0px 0px 0px 0px;\n  opacity: 1;\n  position: relative;\n}\n.container .header[data-v-2c0a6f1f]::after {\n  content: \" \";\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  width: 100%;\n  height: 42px;\n  position: absolute;\n  top: 0;\n  z-index: 1;\n}\n.container .header .header-content[data-v-2c0a6f1f] {\n  position: absolute;\n  z-index: 2;\n}\n.container .main[data-v-2c0a6f1f] {\n  width: 100%;\n  height: calc(100% - 42px);\n  display: flex;\n  position: relative;\n}\n.container .main .nav[data-v-2c0a6f1f] {\n  height: 100%;\n  width: 160px;\n  background: #ffffff;\n  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);\n  border-radius: 0px 0px 0px 0px;\n  opacity: 1;\n  position: absolute;\n  z-index: 999;\n}\n.container .main .content[data-v-2c0a6f1f] {\n  width: calc(100% - 160px);\n  height: 100%;\n  margin-left: 160px;\n}\n.container .main .alive-item[data-v-2c0a6f1f] {\n  width: 100%;\n  height: 42px;\n  background: #ffffff;\n  border-radius: 0px 0px 0px 0px;\n  opacity: 1;\n  overflow: hidden;\n}\n.container .main .page[data-v-2c0a6f1f] {\n  height: calc(100% - 62px);\n  width: calc(100% - 20px);\n  background: #f7f7f7;\n  padding: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
