var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resister-content" },
    [
      _c("Tab", { attrs: { tabData: _vm.tabData, active: _vm.active } }),
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "number" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "text",
                        placeholder: `${
                          _vm.form.sendType ? "请输入邮箱" : "请输入手机号"
                        }`,
                      },
                      model: {
                        value: _vm.form.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "number", $$v)
                        },
                        expression: "form.number",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "sendType",
                          attrs: { slot: "prepend", placeholder: "请选择" },
                          slot: "prepend",
                          model: {
                            value: _vm.form.sendType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sendType", $$v)
                            },
                            expression: "form.sendType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "电话", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "邮箱", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "passCode" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ecode" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          type: "text",
                          maxlength: "20",
                          placeholder: "请输入验证码",
                          oninput: "value=value.replace(/[^0-9]/g,'').trim()",
                        },
                        model: {
                          value: _vm.form.passCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "passCode", $$v)
                          },
                          expression: "form.passCode",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { disabled: _vm.disabled },
                          on: { click: _vm.sendECode },
                        },
                        [_vm._v(_vm._s(_vm.sendCode))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "password" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: _vm.showEye1 ? "text" : "password",
                        placeholder: "请输入新密码",
                      },
                      model: {
                        value: _vm.form.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "suffixEye",
                          attrs: { slot: "suffix" },
                          on: {
                            click: function ($event) {
                              _vm.showEye1 = !_vm.showEye1
                            },
                          },
                          slot: "suffix",
                        },
                        [
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showEye1,
                                expression: "!showEye1",
                              },
                            ],
                            attrs: {
                              className: "openEye",
                              iconClass: "openEye",
                            },
                          }),
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showEye1,
                                expression: "showEye1",
                              },
                            ],
                            attrs: {
                              className: "closeEye",
                              iconClass: "closeEye",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "newpassword" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: _vm.showEye2 ? "text" : "password",
                        placeholder: "请重新输入密码",
                      },
                      model: {
                        value: _vm.form.newpassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "newpassword", $$v)
                        },
                        expression: "form.newpassword",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "suffixEye",
                          attrs: { slot: "suffix" },
                          on: {
                            click: function ($event) {
                              _vm.showEye2 = !_vm.showEye2
                            },
                          },
                          slot: "suffix",
                        },
                        [
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showEye2,
                                expression: "!showEye2",
                              },
                            ],
                            attrs: {
                              className: "openEye",
                              iconClass: "openEye",
                            },
                          }),
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showEye2,
                                expression: "showEye2",
                              },
                            ],
                            attrs: {
                              className: "closeEye",
                              iconClass: "closeEye",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "registe-btn",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.resetPass },
                },
                [_vm._v("完成")]
              ),
              _c("p", { staticClass: "goLogin", on: { click: _vm.goLogin } }, [
                _vm._v("返回登录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }