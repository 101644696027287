const USER_SET = 'USER_SET'
import { userInfo } from '@/api/system'

const user = {
    state: {
        userInfo: null
    },
    mutations: {
        [USER_SET](state, userInfo) {
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
              state.userInfo = userInfo
        }
    },
    actions: {
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                userInfo()
                .then((res) => {
                  commit(USER_SET, res.data)
                  resolve(res.data)
                })
                .catch((err) => {
                  return reject(err)
                })
            })
          }
    },
    getters: {
        userInfo: state => {
            return state.userInfo || JSON.parse(sessionStorage.getItem('userInfo'))
        }
    }
}
export default user
