/**
 * 验证手机号码是否符合
 * @Author   Author
 * @DateTime 2021-7-12T15:47:25+0800
 * @param    {[string]}                 str [description]
 * @return   {Boolean}                    [description]
 */
export function isValidPhone(str) {
    const regPhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    return regPhone.test(str)
}

/**
 * 验证邮箱是否符合
 * @Author   Author
 * @DateTime 2021-7-12T15:47:25+0800
 * @param    {[string]}                 str [description]
 * @return   {Boolean}                    [description]
 */
export function isValidEmail(str) {
    const regEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
    return regEmail.test(str)
}

/**
 * 验证密码是否符合
 * @Author   Author
 * @DateTime 2021-7-12T15:47:25+0800
 * @param    {[string]}                 str [description]
 * @return   {Boolean}                    [description]
 */
 export function isValidPwd(str) {
    const regPwd = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/ // 至少有一个数字字母
    return regPwd.test(str)
}

/**
 * 验证是否全部数字
 * @Author   Author
 * @DateTime 2021-7-12T15:47:25+0800
 * @param    {[string]}                 str [description]
 * @return   {Boolean}                    [description]
 */
 export function isValidNum(str) {
    const regPwd = /^[0-9]*$/ // 验证是否全部数字
    return regPwd.test(str)
}

export function validateRate(rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入基础税率'))
    } else {
      callback()
    }
  }
export function validateId(rule, value, callback) {
    if (value === '') {
        callback(new Error('请输入税号'))
    } else {
        const reg = /^\d{10}$/
        if (reg.test(value)) {
        callback()
        } else {
        callback(new Error('请10位数字的税号'))
        }
    }
}
