<template>
  <el-drawer
    :visible.sync="$store.state.system.messageDrawer"
    :append-to-body="true"
    :size="size"
    :modal="false"
    :wrapperClosable="true"
    :before-close="handleClose"
  >
    <template slot="title">
      <span>
        <span
          :class="{ active: !isActive }"
          class="read"
          @click.stop="handleClick(0)"
        >未读({{ msgData.unread }})</span
        >
        <span
          :class="{ active: isActive }"
          class="read"
          @click.stop="handleClick(1)"
        >已读({{ msgData.read }})</span
        >
      </span>
    </template>
    <div class="view-container">
      <div class="opt">
        <BaseFilter
          :isActive="filterActive"
          :width="438"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="40px">
              <el-form-item label="客户">
                <el-select
                  v-model="filterList.companyId"
                  style="width: 320px;"
                  placeholder="请选择类型"
                >
                  <el-option
                    v-for="item in companyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
      <div v-loading="loading" class="contanier scrollbar">
        <div v-for="(item, $index) of lists" :key="item.id" class="panel">
          <div class="left">
            <img v-if="!isActive" src="@/assets/images/loyout/unReadMsg.png" />
            <img v-if="isActive" src="@/assets/images/loyout/read.png" />
          </div>
          <div class="right">
            <div class="lable">
              <p>
                <img src="@/assets/images/loyout/user.png" />
                客户：{{ item.companyName }}
              </p>
              <p v-if="!isActive">
                <img src="@/assets/images/loyout/unReadMsgIcon.png" />
                <el-link
                  :underline="false"
                  type="primary"
                  @click="read(item.id)"
                >标为已读</el-link
                >
              </p>
            </div>
            <div class="wrapper">
              <input :id="`exp${$index}`" class="exp" type="checkbox" />
              <div class="text">
                <label v-if="byteCount(item.description)" class="btn" :for="`exp${$index}`"></label>
                {{ item.description }}
              </div>
            </div>
            <div class="date">{{ item.createdOn ? item.createdOn.slice(0, item.createdOn.length - 3) : '' }}</div>
          </div>
        </div>
      </div>
      <div class="pager">
        <el-pagination
          :current-page="pagerConfig.pageIndex"
          :page-size="20"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { getMessagePages, workBenchOverview, readMessage } from '@/api/home'
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { mapGetters } from 'vuex'
export default {
  name: 'MessageDrawer',
  components: { BaseFilter },
  props: {
    size: {
      type: Number,
      default: 520
    }
  },
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      filterList: {
        companyId: ''
      },
      msgData: {},
      lists: [],
      isActive: 0,
      filterActive: false,
      loading: false,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    }
  },
  watch: {
    '$store.state.system.messageDrawer'(val) {
      if (val) {
        this.getList(this.pagerConfig)
        this.getSumTotal()
        if (!this.companyDrapdown.length) {
          this.$store.dispatch('getCompanyDrapdown')
        }
      }
    }
  },
  methods: {
    async getList(param) {
      try {
        this.loading = true
        param = {
          ...param,
          queryParam: {
            hasBeenRead: this.isActive,
            ...this.formatterFilter()
          }
        }
        const res = await getMessagePages(param)
        if (res.success) {
          this.loading = false
          this.lists = res.data.list
          this.total = res.data.total
        }
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    async getSumTotal() {
      try {
        const res = await workBenchOverview()
        if (res.success) {
          const msgData = {
            unread: res.data.message.find((a) => a.type === 0).data.value,
            read: res.data.message.find((a) => a.type === 1).data.value
          }
          this.msgData = msgData
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCurrentChange(pageIndex) {
      const param = {
        pageIndex,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.getList(param)
    },
    handleClose() {
      this.$store.commit('CHANGE_MESSAGE_DRAWER', false)
    },
    handleClick(status) {
      this.isActive = status
      this.reset()
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
    },
    sendFilter() {
      if (this.filterList.companyId) {
        this.filterActive = true
      }
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
    },
    reset() {
      this.filterList = {
        companyId: ''
      }
      this.filterActive = false
    },
    formatterFilter() {
      return {
        companyId: this.filterList.companyId && this.filterList.companyId.length > 0
          ? this.filterList.companyId
          : undefined
      }
    },
    // 长度超过107 超过两行
    byteCount(str) {
      //  <summary>获得字符串实际长度，中文2，英文1</summary>
      //  <param name="str">要获得长度的字符串</param>
      let realLength = 0
      const len = str.length
      let charCode = -1
      for (let i = 0; i < len; i++) {
        charCode = str.charCodeAt(i)
        if (charCode >= 0 && charCode <= 128) {
          realLength += 1
        } else {
          realLength += 2
        }
      }
      return realLength > 107
    },
    async read(id) {
      try {
        const res = await readMessage({ id })
        if (res.success) {
          this.$message.success('操作成功')
          const param = {
            pageIndex: 1,
            pageSize: 20
          }
          this.pagerConfig = param
          const msgData = {
            unread: this.msgData.unread - 1,
            read: parseInt(this.msgData.read, 10) + 1
          }
          this.msgData = msgData
          this.getList(param)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.read {
  color: #999999;
  cursor: pointer;
  & + .read {
    margin-left: 30px;
  }
}
.active {
  color: #2395f3;
}
.view-container {
  height: 100%;
  padding: 0 20px;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .contanier {
    width: 100%;
    height: calc(100% - 220px);
    overflow-y: auto;
  }
  .pager {
    padding-top: 40px;
    float: right;
  }
  .panel {
    width: 100%;
    min-height: 117px;
    height: auto;
    background: #fbfbfb;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    .lable {
      margin-top: 20px;
      padding-right: 22px;
      width: calc(100% - 22px);
      height: 19px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 19px;
      img {
        margin-right: 5px;
        vertical-align: middle;
      }
    }
    .date {
      width: calc(100% - 22px);
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 19px;
      margin-bottom: 20px;
    }
    & + .panel {
      margin-top: 10px;
    }
    &::after {
      clear: both;
    }
    .left {
      width: 80px;
      height: auto;
      padding-top: 20px;
      text-align: center;
    }
    .right {
      width: calc(100% - 80px);
      min-height: 117px;
      height: auto;
    }
    .wrapper {
      display: flex;
      padding-right: 22px;
      width: calc(100% - 22px);
      overflow: hidden;
      border-radius: 8px;
      padding-top: 10px;
    }
    .text {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      position: relative;
    }
    .text::before {
      content: "";
      height: calc(100% - 24px);
      float: right;
    }
    .btn {
      float: right;
      clear: both;
      margin-left: 10px;
      font-size: 14px;
      line-height: 24px;
      color: #2395f3;
      cursor: pointer;
    }
    .btn::before {
      content: "展开";
    }
    .exp {
      display: none;
    }
    .exp:checked + .text {
      -webkit-line-clamp: 999;
    }
    .exp:checked + .text .btn::before {
      content: "收起";
    }
  }
}
:deep(.el-drawer__header) {
  border-bottom: 1px solid #f7f7f7;
  margin-bottom: 0;
  padding-bottom: 18px;
  padding-top: 18px;
}
:deep(.el-drawer__body) {
  height: 100%;
}
</style>
