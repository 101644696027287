/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/pubilc.css'
import './icons/index'
import VueClipBoard from 'vue-clipboard2'
import { permissions, treeToList } from './utils/common'
import echarts from './utils/echarts'
import './utils/flexible'
// 按需引入
import {
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Message,
  Popover,
  Drawer,
  Dialog,
  radio,
  RadioGroup,
  Col,
  Row,
  Cascader,
  Upload,
  Table,
  Pagination,
  Loading,
  TableColumn,
  Tag,
  Link,
  Switch,
  Tabs,
  TabPane,
  Avatar,
  Tree,
  MessageBox,
  Tooltip,
  InputNumber,
  DatePicker,
  Autocomplete,
  ColorPicker,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'element-ui'

Vue.config.productionTip = false

Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Link)
Vue.use(Switch)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Drawer)
Vue.use(Dialog)
Vue.use(radio)
Vue.use(RadioGroup)
Vue.use(Col)
Vue.use(Row)
Vue.use(Cascader)
Vue.use(Upload)
Vue.use(Table)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Tag)
Vue.use(Avatar)
Vue.use(TableColumn)
Vue.use(Tree)
Vue.use(Tooltip)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(Autocomplete)
Vue.use(ColorPicker)
Vue.use(Progress)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.use(VueClipBoard)

// dialog拖拽
Vue.directive('drag', (el) => {
  // inserted (el, binding, vnode, oldVnode) {
  Vue.nextTick(() => {
    const isThemeModal = el.classList.contains('el-dialog__wrapper')
    const dialogHeaderEl = isThemeModal
      ? el.querySelector('.el-dialog__header')
      : document.querySelector('.el-dialog__header')
    const dragDom = isThemeModal
      ? el.querySelector('.el-dialog')
      : document.querySelector('.el-dialog el-dialog--center')
    dialogHeaderEl.style.cssText += ';cursor:move;'
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = (function() {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr]
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr]
      }
    })()

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop

      const screenWidth = document.body.clientWidth // body当前宽度
      const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取)

      const dragDomWidth = dragDom.offsetWidth // 对话框宽度
      const dragDomheight = dragDom.offsetHeight // 对话框高度

      const minDragDomLeft = dragDom.offsetLeft
      const maxDragDomLeft =
        screenWidth -
        dragDom.offsetLeft -
        dragDomWidth -
        (isThemeModal ? 10 : 0)

      const minDragDomTop = dragDom.offsetTop
      const maxDragDomTop =
        screenHeight -
        dragDom.offsetTop -
        dragDomheight -
        (isThemeModal ? 10 : 0)

      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left')
      let styT = sty(dragDom, 'top')

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        // eslint-disable-next-line no-useless-escape
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
        // eslint-disable-next-line no-useless-escape
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
      } else {
        styL = +styL.replace(/\px/g, '')
        styT = +styT.replace(/\px/g, '')
      }

      document.onmousemove = function(e) {
        // 通过事件委托，计算移动的距离
        let left = e.clientX - disX
        let top = e.clientY - disY

        // 边界处理
        if (-left > minDragDomLeft) {
          left = -minDragDomLeft
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft
        }

        if (-top > minDragDomTop) {
          top = -minDragDomTop
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop
        }
        // 移动当前元素
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
      }

      document.onmouseup = function() {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  })
})

// 按钮权限
Vue.directive('permissson', {
  inserted(el, binding, vnode) {
    const { value } = binding
    const menuList = store.getters.initRouter
    const $this = vnode.context
    const menuTree = permissions($this.$route.meta.code, menuList)
    const menu = treeToList([menuTree], 'children')
    const flag = menu.find((a) => a.code === value)
    if (!flag) {
      if (el.parentNode) {
        el.parentNode.removeChild(el)
      } else {
        el.innerHTML = ''
      }
    } else {
      if (flag.key === 'Tab') {
        console.log('flag.children', flag.children)
        if (flag.children.length > 0) {
            el && el.setAttribute('code', value)
        } else {
          if (el.parentNode) {
            el.parentNode.removeChild(el)
          } else {
            el.innerHTML = ''
          }
        }
      } else {
        el && el.setAttribute('code', value)
      }
    }
  }
})

// 文字超出显示
Vue.directive('tooltip', {
  bind(el, binding) {
    el.title = el.textContent
    el.style.width = binding.value
    if (el.scrollWidth > binding.value) {
      el.textContent = el.textContent.slice(0, binding.value) + '...'
    }
    el.addEventListener('mouseenter', () => {
      el.style.position = 'relative'
      el.style.zIndex = '999'
    })
    el.addEventListener('mouseleave', () => {
      el.style.position = null
      el.style.zIndex = null
    })
    el.addEventListener('click', () => {
      if (el.scrollWidth > binding.value) {
        navigator.clipboard.writeText(el.textContent)
        Message.success('复制文字成功')
      }
    })
  }
})
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$echarts = echarts
Vue.prototype.$loginTest = process.env.VUE_APP_LOGIN_TEST
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
