const ADD_SIGN = 'ADD_SIGN'
const CHANGE_UPCOME_DRAWER = 'CHANGE_UPCOME_DRAWER'
const CHANGE_MESSAGE_DRAWER = 'CHANGE_MESSAGE_DRAWER'
const CHECK_COOKIE = 'CHECK_COOKIE'
const CHANGE_COLLAPSE = 'CHANGE_COLLAPSE'
const ADD_USERROLE = 'ADD_USERROLE'
const SET_ADDRESS_BOOK = 'SET_ADDRESS_BOOK'
const SET_COMPANY_DRAPDOWN = 'SET_COMPANY_DRAPDOWN'
const SET_ISNEWMSG = 'SET_ISNEWMSG'
const SET_ISNEWUPCOME = 'SET_ISNEWUPCOME'
const SET_SUPPLIER_DROPDOWN = 'SET_SUPPLIER_DROPDOWN'
const SET_HARBOR_DRAPDOWN = 'SET_HARBOR_DRAPDOWN'
import { keySign } from '@/api/system'
import { getAddressBookDrapdown, harborDrapDown } from '@/api/basic'
import { getUserRolePages } from '@/api/setting'
import { getCompanyDrapdown, getSupplierDropDownList } from '@/api/system'
const system = {
  state: {
    sign: null,
    isCollapse: true,
    upcomeDrawer: false,
    messageDrawer: false,
    isCheck: false,
    addressBookOptions: [],
    companyDrapdown: [],
    supplierDropdown: [],
    harborDropdown: [],
    userRole: null,
    isNewMeg: false,
    isNewUpcome: false
  },
  mutations: {
    [ADD_SIGN](state, sign) {
      state.sign = sign
    },
    [CHANGE_UPCOME_DRAWER](state, bool) {
      state.upcomeDrawer = bool
    },
    [CHANGE_MESSAGE_DRAWER](state, bool) {
      state.messageDrawer = bool
    },
    [CHECK_COOKIE](state, bool) {
      state.isCheck = bool
    },
    [CHANGE_COLLAPSE](state, bool) {
      state.isCollapse = bool
    },
    [ADD_USERROLE](state, userRole) {
      state.userRole = userRole
    },
    [SET_ADDRESS_BOOK](state, data) {
      state.addressBookOptions = data
    },
    [SET_COMPANY_DRAPDOWN](state, data) {
      state.companyDrapdown = data
    },
    [SET_SUPPLIER_DROPDOWN](state, data) {
      state.supplierDropdown = data
    },
    [SET_HARBOR_DRAPDOWN](state, data) {
      state.harborDropdown = data
    },
    [SET_ISNEWMSG](state, bool) {
      state.isNewMeg = bool
    },
    [SET_ISNEWUPCOME](state, bool) {
      state.isNewUpcome = bool
    }
  },
  actions: {
    getSign({ commit }, key) {
      return new Promise((resolve, reject) => {
        keySign(key)
          .then((res) => {
            commit(ADD_SIGN, res.data)
            resolve(res.data)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getUserRole({ commit }) {
      return new Promise((resolve, reject) => {
        getUserRolePages({
          'pageIndex': 1,
          'pageSize': 1000
        })
          .then((res) => {
            commit(ADD_USERROLE, res.data.list)
            resolve(res.data)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getAddressBookOptions({ commit }, { type, companyId }) {
      return new Promise((resolve, reject) => {
        getAddressBookDrapdown({
          type, companyId
        })
          .then((res) => {
            if (res.success) {
              commit(SET_ADDRESS_BOOK, res.data)
            }
            resolve(res)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getCompanyDrapdown({ commit }) {
      return new Promise((resolve, reject) => {
        getCompanyDrapdown()
          .then((res) => {
            if (res.success) {
              const arr = res.data.map((item) => {
                return {
                  ...item,
                  value: item.id,
                  label: item.code + '-' + item.wbName
                }
              })
              commit(SET_COMPANY_DRAPDOWN, arr)
            }
            resolve(res)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getSupplierDropDownList({ commit }, obj) {
      return new Promise((resolve, reject) => {
        getSupplierDropDownList(obj)
          .then((res) => {
            if (res.success) {
              const arr = res.data.map((item) => {
                return {
                  value: item.id,
                  label: item.code + '-' + item.name
                }
              })
              commit(SET_SUPPLIER_DROPDOWN, arr)
              resolve(arr)
            }
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    getHarborDrapDown({ commit }, obj) {
      return new Promise((resolve, reject) => {
        harborDrapDown(obj)
          .then((res) => {
            if (res.success) {
              const arr = res.data.map((item) => {
                return {
                  value: item.label.split('-')[0],
                  label: item.code + '-' + item.label
                }
              })
              commit(SET_HARBOR_DRAPDOWN, arr)
              resolve(arr)
            }
          })
          .catch((err) => {
            return reject(err)
          })
      })
    }
  },
  getters: {
    sign: state => state.sign,
    isCheck: state => state.isCheck,
    userRole: state => state.userRole,
    isCollapse: state => state.isCollapse,
    upcomeDrawer: state => state.upcomeDrawer,
    messageDrawer: state => state.messageDrawer,
    addressBookOptions: (state) => state.addressBookOptions,
    companyDrapdown: (state) => state.companyDrapdown,
    supplierDropdown: (state) => state.supplierDropdown,
    harborDropdown: (state) => state.harborDropdown,
    isNewMeg: (state) => state.isNewMeg,
    isNewUpcome: (state) => state.isNewUpcome
  }
}

export default system
