<template>
  <div class="tab-content">
    <div
      v-for="(item, $index) in tabData"
      :key="$index"
      class="tab-item"
      :class="[{'active': active === $index+1}]"
      @click="tabChange($index+1)"
    >
      <span>{{ item }}</span>
      <div v-if="active=== $index+1" class="active-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabData: {
      type: Array,
      default: function() {
        return []
      }
    },
    active: {
      type: Number,
      default: 1
    }
  },
  methods: {
    tabChange(index) {
        if (index !== this.active && this.tabData.length > 1) {
            this.$emit('tabChange', index)
        }
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: 100%;
  height: 38px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .tab-item {
    width: auto;
    height: 100%;
    color: #999999;
    cursor: pointer;
    & + .tab-item {
      margin-left: 41px;
    }
    span {
      width: auto;
      height: 24px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
    }
    .active-line {
      width: 32px;
      height: 4px;
      background: #2395f3;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 8px auto 0;
    }
  }
  .active {
    color: #333333;
  }
}
</style>
