var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alive-nav-content" },
    [
      _vm._l(_vm.visitedTabsView, function (tag) {
        return _c(
          "div",
          {
            key: tag.path,
            staticClass: "tags-view-item",
            on: {
              click: function ($event) {
                return _vm.go(tag.path)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "tag flex_c_c",
                class: [{ active: _vm.isActive(tag) }],
              },
              [
                _c("span", [_vm._v(_vm._s(tag.name))]),
                _c(
                  "span",
                  {
                    staticClass: "closebox flex_c_c",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleClose(tag)
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { iconClass: "close", className: "icon" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        )
      }),
      _c("div", { staticClass: "reload flex_c_c", on: { click: _vm.reload } }, [
        _c("img", {
          attrs: {
            title: "刷新",
            src: require("@/assets/images/loyout/reload.png"),
          },
        }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }