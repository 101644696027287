import MainLoyout from '@/loyout/MainLoyout.vue'
import config from '@/utils/config'
const routes = [
  {
    path: '/home',
    name: 'home',
    component: MainLoyout,
    meta: {
      title: '首页',
      icon: 'home',
      code: config.menuCode.SY
    },
    children: [
      {
        path: 'workbench',
        name: 'workbench',
        meta: {
          title: '工作台',
          code: config.menuCode.GZT,
          doc: '#首页'
        },
        component: () => import('@/views/home/Index')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: MainLoyout,
    meta: {
      title: '订单管理',
      icon: 'order',
      code: config.menuCode.DDGL
    },
    children: [
      {
        path: 'OrderSearch',
        name: 'OrderSearch',
        meta: {
          title: '订单查询',
          code: config.menuCode.DDCX,
          doc: '#订单查询'
        },
        component: () => import('@/views/order/OrderList')
      },
      {
        path: 'Track',
        name: 'Track',
        meta: {
          title: '轨迹查询',
          code: config.menuCode.GJCX,
          doc: '#轨迹查询'
        },
        component: () => import('@/views/order/Track')
      },
      {
        path: 'FeeSearch',
        name: 'FeeSearch',
        meta: {
          title: '预估费用查询',
          code: config.menuCode.YGFYCX,
          doc: '#预估费用查询'
        },
        component: () => import('@/views/order/FeeSearch')
      },
      {
        path: 'FeeTrial',
        name: 'FeeTrial',
        meta: {
          title: '费用试算',
          code: config.menuCode.FYSS,
          doc: '#费用试算'
        },
        component: () => import('@/views/order/FeeTrial')
      },
      {
        path: 'Scan',
        name: 'Scan',
        meta: {
          title: '扫码换单',
          code: config.menuCode.SMHD,
          doc: '#扫码换单'
        },
        component: () => import('@/views/order/Scan')
      },
      {
        path: 'CardInquiry',
        name: 'CardInquiry',
        meta: {
          title: '卡派询价',
          code: config.menuCode.KPXJ,
          doc: '#卡派询价'
        },
        component: () => import('@/views/order/CardInquiry')
      }
    ]
  },
  {
    path: '/billManage',
    name: 'billManage',
    component: MainLoyout,
    meta: {
      title: '提单管理',
      icon: 'bill',
      code: config.menuCode.TDGL
    },
    children: [
      {
        path: 'ISF',
        name: 'ISF',
        meta: {
          title: 'ISF',
          code: config.menuCode.ISF,
          doc: '#isf'
        },
        component: () => import('@/views/bill/ISF')
      },
      {
        path: 'AirBill',
        name: 'AirBill',
        meta: {
          title: '空运提单',
          code: config.menuCode.KYTD,
          doc: '#空运提单'
        },
        component: () => import('@/views/bill/Air')
      },
      {
        path: 'ShipBill',
        name: 'ShipBill',
        meta: {
          title: '海运提单',
          code: config.menuCode.HYTD,
          doc: '#海运提单'
        },
        component: () => import('@/views/bill/Ship')
      },
      {
        path: 'TruckList',
        name: 'TruckList',
        meta: {
          title: '卡派列表',
          code: config.menuCode.KPLB,
          doc: '#卡派列表'
        },
        component: () => import('@/views/bill/TruckList')
      },
      {
        path: 'SetBill',
        name: 'SetBill',
        meta: {
          title: '提单设置',
          code: config.menuCode.TDSZ,
          doc: '#提单设置'
        },
        component: () => import('@/views/bill/SetBill')
      },
      {
        path: 'CheckList',
        name: 'CheckList',
        meta: {
          title: '查验列表',
          code: config.menuCode.CYLB,
          doc: '#查验列表'
        },
        component: () => import('@/views/bill/CheckList')
      }
    ]
  },
  {
    path: '/company',
    name: 'company',
    component: MainLoyout,
    meta: {
      title: '客户管理',
      icon: 'company',
      code: config.menuCode.KHGL
    },
    children: [
      {
        path: 'Company',
        name: 'Company',
        meta: {
          title: '客户管理',
          code: config.menuCode.KHGL,
          doc: '#客户管理'
        },
        component: () => import('@/views/company/Company')
      }
    ]
  },
  {
    path: '/supply',
    name: 'supply',
    component: MainLoyout,
    meta: {
      title: '供应商管理',
      icon: 'supply',
      code: config.menuCode.GYSGL
    },
    children: [
      {
        path: 'Supply',
        name: 'Supply',
        meta: {
          title: '供应商管理',
          code: config.menuCode.GYSGL,
          doc: '#供应商管理'
        },
        component: () => import('@/views/supply/Supply')
      }
    ]
  },
  {
    path: '/const',
    name: 'const',
    component: MainLoyout,
    meta: {
      title: '财务管理',
      icon: 'const',
      code: config.menuCode.CWGL
    },
    children: [
      {
        path: 'BillWnquiry',
        name: 'BillWnquiry',
        meta: {
          title: '账单查询',
          code: config.menuCode.ZDCX,
          doc: '#账单查询'
        },
        component: () => import('@/views/const/BillWnquiry')
      },
      {
        path: 'ClaimPayment',
        name: 'ClaimPayment',
        meta: {
          title: '申付',
          code: config.menuCode.SF,
          doc: '#申付'
        },
        component: () => import('@/views/const/ClaimPayment')
      },
      {
        path: 'CopeWith',
        name: 'CopeWith',
        meta: {
          title: '应付管理',
          code: config.menuCode.YFGL,
          doc: '#应付管理'
        },
        component: () => import('@/views/const/CopeWith')
      },
      {
        path: 'Receivables',
        name: 'Receivables',
        meta: {
          title: '应收管理',
          code: config.menuCode.YSGL,
          doc: '#应收管理'
        },
        component: () => import('@/views/const/Receivables')
      },
      {
        path: 'DispatchReconciliation',
        name: 'DispatchReconciliation',
        meta: {
          title: '派送对账',
          code: config.menuCode.PSDZ,
          doc: '#派送对账'
        },
        component: () => import('@/views/const/DispatchReconciliation')
      },
      {
        path: 'WaterBill',
        name: 'WaterBill',
        meta: {
          title: '充值水单',
          code: config.menuCode.CZSD,
          doc: '#充值水单'
        },
        component: () => import('@/views/const/WaterBill')
      },
      {
        path: 'FeeQuery',
        name: 'FeeQuery',
        meta: {
          title: '费用查询',
          code: config.menuCode.FYCX,
          doc: '#费用查询'
        },
        component: () => import('@/views/const/FeeQuery')
      },
      {
        path: 'AccountCustom',
        name: 'AccountCustom',
        meta: {
          title: '客户账户管理',
          code: config.menuCode.KHZHGL,
          doc: '#客户账户管理'
        },
        component: () => import('@/views/const/AccountCustom')
      },
      {
        path: 'AccountsReceivable',
        name: 'AccountsReceivable',
        meta: {
          title: '收款账户管理',
          code: config.menuCode.SKZHGL,
          doc: '#收款账户管理'
        },
        component: () => import('@/views/const/AccountsReceivable')
      },
      {
        path: 'FeeCode',
        name: 'FeeCode',
        meta: {
          title: '费用代码管理',
          code: config.menuCode.FYDMGL,
          doc: '#费用代码管理'
        },
        component: () => import('@/views/const/FeeCode')
      }
    ]
  },
  {
    path: '/workList',
    name: 'workList',
    component: MainLoyout,
    meta: {
      title: '工单',
      icon: 'work',
      code: config.menuCode.GD
    },
    children: [
      {
        path: '',
        name: 'WorkList',
        meta: {
          title: '工单',
          code: config.menuCode.GD,
          doc: '#工单'
        },
        component: () => import('@/views/workBill/Index.vue')
      }]
  },
  {
    path: '/statisticalAnalysis',
    name: 'statisticalAnalysis',
    component: MainLoyout,
    meta: {
      title: '统计分析',
      icon: 'statistics',
      code: config.menuCode.TJFX
    },
    children: [
      {
        path: 'OperatingStatement',
        name: 'OperatingStatement',
        meta: {
          title: '运营报表',
          code: config.menuCode.YYBB,
          doc: '#运营报表'
        },
        component: () => import('@/views/statistics/OperatingStatement.vue')
      },
      {
        path: 'GrossProfit',
        name: 'GrossProfit',
        meta: {
          title: '毛利统计',
          code: config.menuCode.MLTJ,
          doc: '#毛利统计'
        },
        component: () => import('@/views/statistics/GrossProfit')
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: MainLoyout,
    meta: {
      title: '系统设置',
      icon: 'system',
      code: config.menuCode.XTSZ
    },
    children: [
      {
        path: 'UserOpt',
        name: 'UserOpt',
        meta: {
          title: '用户管理',
          code: config.menuCode.YHGL,
          doc: '#用户管理'
        },
        component: () => import('@/views/system/UserInfo')
      },
      {
        path: 'BaseData',
        name: 'BaseData',
        meta: {
          title: '基础资料',
          code: config.menuCode.JCZL,
          doc: '#基础资料'
        },
        component: () => import('@/views/system/Scin')
      },
      {
        path: 'UserRole',
        name: 'UserRole',
        meta: {
          title: '角色权限',
          code: config.menuCode.JSQX,
          doc: '#角色权限'
        },
        component: () => import('@/views/system/UserRole')
      },
      {
        path: 'Rate',
        name: 'Rate',
        meta: {
          title: '汇率设置',
          code: config.menuCode.HLSZ,
          doc: '#汇率设置'
        },
        component: () => import('@/views/system/Rate')
      },
      {
        path: 'WorkBill',
        name: 'WorkBill',
        meta: {
          title: '工单设置',
          code: config.menuCode.GDSZ,
          doc: '#工单设置'
        },
        component: () => import('@/views/system/WorkBill')
      },
      {
        path: 'BillSetting',
        name: 'BillSetting',
        meta: {
          title: '制单设置',
          code: config.menuCode.ZDSZ,
          doc: '#制单设置'
        },
        component: () => import('@/views/system/BillSetting')
      },
      {
        meta: {
          title: '税率库',
          code: config.menuCode.SLK,
          doc: '#税率库'
        },
        path: 'TaxRate',
        name: 'TaxRate',
        component: () => import('@/views/system/TaxRate.vue')
      },
      {
        meta: {
          title: '产品库',
          code: config.menuCode.CPK,
          doc: '#产品库'
        },
        path: 'Product',
        name: 'Product',
        component: () => import('@/views/system/Product.vue')
      }
    ]
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('@/views/PDF'),
    meta: {
      title: 'PDF',
      icon: 'system',
      code: config.menuCode.PDF
    }
  }
]
export default routes
