var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "header" },
      [_c("Header", { staticClass: "header-content" })],
      1
    ),
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        {
          staticClass: "nav",
          style: { width: _vm.isCollapse ? "60px" : "160px" },
        },
        [_c("Nav")],
        1
      ),
      _c(
        "div",
        {
          staticClass: "content",
          style: {
            width: _vm.isCollapse ? "calc(100% - 60px)" : "calc(100% - 160px)",
            marginLeft: _vm.isCollapse ? "60px" : "160px",
          },
        },
        [
          _c("div", { staticClass: "alive-item" }, [_c("alive-nav")], 1),
          _c(
            "div",
            { staticClass: "page" },
            [_c("keep-alive", [_c("router-view")], 1)],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }