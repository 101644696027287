<template>
  <div class="container">
    <img class="logobg" src="@/assets/images/logobg.png">
    <div :class="['form', showIcon === 'show' ? 'showIcon' : '']">
      <router-view></router-view>
    </div>
    <div class="copyright">
      版权所有 © 2023 FEEPOWER <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021066614号-3</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showIcon: this.$loginTest
    }
  }
}
</script>

<style scoped lang="less">
 .container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #F8FBFF;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background: url('../assets/images/loginbg.jpg') 50% no-repeat;;
  background-size: cover;
 }
 .logobg {
  position: absolute;
  top: 40px;
  left: 64px;
  width: 356px;
  height: 41px;
 }
 .form {
  position: relative;
  width: 500px;
  height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.0500);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-right: 220px;
 }
 .showIcon {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 75px;
    width: 75px;
    background: url('../assets/images/development.png');
    background-size: 75px;
  }
 }
 .copyright {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  white-space: nowrap;
  a {
    color: #999999;
    text-decoration: none;
  }
 }
</style>
