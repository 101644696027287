var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.$store.state.system.messageDrawer,
        "append-to-body": true,
        size: _vm.size,
        modal: false,
        wrapperClosable: true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.$store.state.system, "messageDrawer", $event)
        },
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("span", [
          _c(
            "span",
            {
              staticClass: "read",
              class: { active: !_vm.isActive },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick(0)
                },
              },
            },
            [_vm._v("未读(" + _vm._s(_vm.msgData.unread) + ")")]
          ),
          _c(
            "span",
            {
              staticClass: "read",
              class: { active: _vm.isActive },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick(1)
                },
              },
            },
            [_vm._v("已读(" + _vm._s(_vm.msgData.read) + ")")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "view-container" }, [
        _c(
          "div",
          { staticClass: "opt" },
          [
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.filterActive, width: 438 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.filterList, "label-width": "40px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: { placeholder: "请选择类型" },
                                model: {
                                  value: _vm.filterList.companyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "companyId", $$v)
                                  },
                                  expression: "filterList.companyId",
                                },
                              },
                              _vm._l(_vm.companyOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "contanier scrollbar",
          },
          _vm._l(_vm.lists, function (item, $index) {
            return _c("div", { key: item.id, staticClass: "panel" }, [
              _c("div", { staticClass: "left" }, [
                !_vm.isActive
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/loyout/unReadMsg.png"),
                      },
                    })
                  : _vm._e(),
                _vm.isActive
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/loyout/read.png"),
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "lable" }, [
                  _c("p", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/loyout/user.png"),
                      },
                    }),
                    _vm._v(" 客户：" + _vm._s(item.companyName) + " "),
                  ]),
                  !_vm.isActive
                    ? _c(
                        "p",
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/loyout/unReadMsgIcon.png"),
                            },
                          }),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.read(item.id)
                                },
                              },
                            },
                            [_vm._v("标为已读")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "wrapper" }, [
                  _c("input", {
                    staticClass: "exp",
                    attrs: { id: `exp${$index}`, type: "checkbox" },
                  }),
                  _c("div", { staticClass: "text" }, [
                    _vm.byteCount(item.description)
                      ? _c("label", {
                          staticClass: "btn",
                          attrs: { for: `exp${$index}` },
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.description) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    _vm._s(
                      item.createdOn
                        ? item.createdOn.slice(0, item.createdOn.length - 3)
                        : ""
                    )
                  ),
                ]),
              ]),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "pager" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.pagerConfig.pageIndex,
                "page-size": 20,
                layout: "total, prev, pager, next",
                total: _vm.total,
              },
              on: { "current-change": _vm.handleCurrentChange },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }