import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/cas/Login.vue'
import Resister from '../views/cas/Resister.vue'
import ResetPassword from '../views/cas/ResetPassword.vue'
import CasLouyout from '@/loyout/CasLoyout.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store'
import { getUserMenuPermission } from '@/api/setting'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: CasLouyout,
    children: [
      {
        path: '',
        component: Login
      }
    ]
  },
  {
    path: '/login',
    component: CasLouyout,
    children: [
      {
        path: '',
        component: Login
      }
    ]
  },
  {
    path: '/register',
    component: CasLouyout,
    children: [
      {
        path: '',
        component: Resister
      }
    ]
  },
  {
    path: '/resetPassword',
    component: CasLouyout,
    children: [
      {
        path: '',
        component: ResetPassword
      }
    ]
  },
  {
    path: '/demoTable',
    name: 'demoTable',
    component: () => import('../views/DemoTable.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 解决控制台中的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const whiteList = ['/', '/login', '/register', '/demoTable', '/resetPassword'] // 白名单
router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (whiteList.includes(to.path)) {
    next()
  } else {
    if (store.getters.isSuccess) {
      next()
    } else {
      if (store.getters.initRouterComplate) {
        store.commit('COMPLETE', true)
        router.addRoutes(store.getters.addRouters)
        // 404页面最后添加， 不然会有刷新404问题
        const newRouter = {
          path: '*',
          name: 'nofind',
          component: () => import('@/views/NoFind.vue')
        }
        router.addRoutes([newRouter])
        next(to)
      } else {
        getUserMenuPermission().then((res) => {
          if (res.success) {
            store.commit('ADD_INITROUTER', res.data)
            store.dispatch('addRouter').then(() => {
              store.commit('COMPLETE', true)
              router.addRoutes(store.getters.addRouters)
              // 404页面最后添加， 不然会有刷新404问题
              const newRouter = {
                path: '*',
                name: 'nofind',
                component: () => import('@/views/NoFind.vue')
              }
              router.addRoutes([newRouter])
              next(to)
            })
          } else {
            next('/')
            NProgress.done()
          }
        })
      }
    }
  }
})
router.afterEach(() => {
  NProgress.done() // finish progress bar
})

export default router
