var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-content" },
    _vm._l(_vm.tabData, function (item, $index) {
      return _c(
        "div",
        {
          key: $index,
          staticClass: "tab-item",
          class: [{ active: _vm.active === $index + 1 }],
          on: {
            click: function ($event) {
              return _vm.tabChange($index + 1)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(item))]),
          _vm.active === $index + 1
            ? _c("div", { staticClass: "active-line" })
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }