/* eslint-disable no-undef */
const CryptoJS = require('crypto-js')
const supplyCode = CryptoJS.AES.decrypt(window.config, '3376E34B3B0A7F1C').toString(CryptoJS.enc.Utf8)
export default {
  supplyCode,
  imgSrc: 'https://itms-officialwebsite-dev.oss-cn-shenzhen.aliyuncs.com/',
  menuCode: {
    SY: 'SY', // 首页
    GZT: 'GZT', // 工作台
    XTSZ: 'XTSZ', // 系统设置
    YHGL: 'YHGL', // 用户管理
    JSQX: 'JSQX', // 角色权限
    KHGL: 'KHGL', // 客户管理
    HLSZ: 'HLSZ', // 汇率设置
    GDSZ: 'GDSZ', // 工单设置
    ZDSZ: 'ZDSZ', // 制单设置
    ZDCX: 'ZDCX', // 账单查询
    SF: 'SF', // 账单查询
    YFGL: 'YFGL', // 应付管理
    YSGL: 'YSGL', // 应收管理
    PSDZ: 'PSDZ', // 派送对账
    CZSD: 'CZSD', // 充值水单
    FYCX: 'FYCX', // 费用查询
    KHZHGL: 'KHZHGL', // 客户账户管理
    SKZHGL: 'SKZHGL', // 收款账户管理
    FYDMGL: 'FYDMGL', // 费用代码管理
    CWGL: 'CWGL', // 财务管理
    GYSGL: 'GYSGL', // 供应商管理
    DDGL: 'DDGL', // 订单管理
    DDCX: 'DDCX', // 订单查询
    GJCX: 'GJCX', // 轨迹查询
    FYSS: 'FYSS', // 费用试算
    BTKD: 'BTKD', // 本土快递
    GJKD: 'GJKD', // 国际快递
    KP: 'KP', // 卡派
    YGFYCX: 'YGFYCX', // 预估费用查询
    TDGL: 'TDGL', // 提单管理
    TDSZ: 'TDSZ', // 提单设置
    KYTD: 'KYTD', // 空运提单
    HYTD: 'HYTD', // 海运提单
    ISF: 'ISF', // ISF
    PDF: 'PDF', // PDF
    SLK: 'SLK', // 税率库
    CPK: 'CPK', // 产品库
    KPLB: 'KPLB', // 卡派列表,
    SMHD: 'SMHD', // 扫码换单
    JCZL: 'JCZL', // 基础资料
    GD: 'GD', // 工单
    MLTJ: 'MLTJ', // 毛利统计
    CYLB: 'CYLB', // 查验列表
    TJFX: 'TJFX', // 统计分析
    YYBB: 'YYBB', // 运营报表
    KPXJ: 'KPXJ' // 卡派询价
  },
  userTypeOption: [
    {
      value: 100,
      label: '操作'
    },
    {
      value: 101,
      label: '财务'
    },
    {
      value: 102,
      label: '销售'
    },
    {
      value: 103,
      label: '客服'
    },
    {
      value: 106,
      label: '公司领导'
    },
    {
      value: 109,
      label: '外部人员'
    },
    {
      value: 166,
      label: '管理员'
    }
  ],
  dimensionOptions: [
    {
      value: 0,
      label: '英寸'
    },
    {
      value: 1,
      label: '厘米'
    }
    // {
    //   value: 2,
    //   label: '厘米'
    // }
  ],
  calculateOptions: [
    {
      value: 0,
      label: '单价-按件'
    },
    {
      value: 1,
      label: '单价-按票'
    },
    {
      value: 2,
      label: '固定价-按件'
    },
    {
      value: 3,
      label: '固定价-按票'
    }
  ],
  weightUnitOptions: [
    {
      value: 0,
      label: '英镑'
    },
    {
      value: 1,
      label: '千克'
    }
  ],
  limitTypeOptions: [
    {
      value: 1,
      label: '限重'
    },
    {
      value: 2,
      label: '限额'
    }
  ],
  // 优先级(0-9，0最高)
  calculateLevelOptions: [
    {
      value: 0,
      label: 0
    },
    {
      value: 1,
      label: 1
    },
    {
      value: 2,
      label: 2
    },
    {
      value: 3,
      label: 3
    },
    {
      value: 4,
      label: 4
    },
    {
      value: 5,
      label: 5
    },
    {
      value: 6,
      label: 6
    },
    {
      value: 7,
      label: 7
    },
    {
      value: 8,
      label: 8
    },
    {
      value: 9,
      label: 9
    }
  ],
  // 计算方式
  calculateTypelOptions: [
    {
      value: 0,
      label: '按票'
    },
    {
      value: 1,
      label: '按件'
    }
  ],
  // 账号附加费计算维度
  caculateDimensionOptions: [
    {
      value: 0,
      label: '不限'
    },
    {
      value: 1,
      label: '分区'
    }
  ],
  // 限额
  unitsCodeOptions: [
    {
      value: 'RMB',
      label: 'RMB'
    },
    {
      value: 'USD',
      label: 'USD'
    },
    {
      value: 'KG',
      label: 'KG'
    },
    {
      value: 'LB',
      label: 'LB'
    },
    {
      value: 'GBP',
      label: 'GBP'
    },
    {
      value: 'HKD',
      label: 'HKD'
    }
  ],
  typeOptions: [
    {
      value: 0,
      label: '邮编'
    },
    {
      value: 1,
      label: '国家代码'
    }
  ],
  accountPriceTplOptions: [
    {
      value: 0,
      label: '不限'
    },
    {
      value: 1,
      label: '住宅'
    },
    {
      value: 2,
      label: '商业'
    }
  ],
  // 水单类型
  waterBillType: [
    {
      value: 0,
      label: '待审核'
    },
    {
      value: 1,
      label: '审核通过'
    },
    {
      value: 2,
      label: '已到账'
    },
    {
      value: -2,
      label: '退回'
    },
    {
      value: -1,
      label: '审核不通过'
    }
  ],
  orderStatusOptions: [
    {
      value: 10000,
      label: '全部',
      permissson: 'QB'
    },
    {
      value: 0,
      label: '待上传',
      permissson: 'DSC'
    },
    {
      value: 1,
      label: '待上网',
      permissson: 'DSW'
    },
    {
      value: 2,
      label: '运输中',
      permissson: 'YSZ'
    },
    {
      value: 3,
      label: '签收',
      permissson: 'QS'
    },
    {
      value: 4,
      label: '退回',
      permissson: 'TH'
    },
    {
      value: 10,
      label: '非系统制单',
      permissson: 'FXTZD'
    },
    {
      value: -1,
      label: '取消',
      permissson: 'QX'
    }
  ],
  expressSupplierOptions: [
    {
      value: 'UPS',
      label: 'UPS'
    },
    {
      value: 'FedEx',
      label: 'FedEx'
    },
    {
      value: 'DHL',
      label: 'DHL'
    },
    {
      value: 'DPD',
      label: 'DPD'
    },
    {
      value: 'FEEPOWER',
      label: 'FEEPOWER'
    }
  ],
  originCountryOptions: [
    {
      label: 'Los Angeles',
      value: 3403
    },
    {
      label: 'Chicago',
      value: 3424
    },
    {
      label: 'New York',
      value: 3451
    }
  ],
  expressServiceOptions: [
    {
      value: 'Ground',
      label: 'Ground'
    },
    {
      value: 'MWT',
      label: 'MWT'
    },
    {
      value: 'GFP',
      label: 'GFP'
    },
    {
      value: 'HundredWeight',
      label: 'HundredWeight'
    },
    {
      value: 'Truck',
      label: 'Truck'
    }
  ],
  billTypeOptions: [
    {
      value: 0,
      label: '直单'
    },
    {
      value: 1,
      label: '分单'
    }
  ],
  billStatusOptions: [
    {
      value: '',
      label: '全部',
      permissson: 'QB'
    },
    {
      value: 0,
      label: '待处理',
      permissson: 'DCL'
    },
    {
      value: 1,
      label: '清关中',
      permissson: 'QGZ'
    },
    {
      value: 2,
      label: '提拆交',
      permissson: 'TCJ'
    },
    {
      value: 3,
      label: '异常',
      permissson: 'YC'
    },
    {
      value: 4,
      label: '完结',
      permissson: 'WJ'
    }
  ],
  billShipSubtabOptions: [
    [
      {
        value: 0,
        label: '未发送'
      },
      {
        value: 1,
        label: '待确认'
      },
      {
        value: 1000,
        label: '拒绝'
      }
    ],
    [
      {
        value: 2,
        label: '待发预报'
      },
      {
        value: 3,
        label: '预报已发送'
      },
      {
        value: 4,
        label: '预报已接受'
      },
      {
        value: 5,
        label: '税金待确认'
      },
      {
        value: 6,
        label: '清关中'
      },
      {
        value: 7,
        label: '查验中'
      },
      {
        value: 8,
        label: '已放行'
      }
    ],
    [
      {
        value: 100,
        label: '运输中'
      },
      {
        value: 101,
        label: '到港'
      },
      {
        value: 102,
        label: '卸船'
      },
      {
        value: 103,
        label: '预约'
      },
      {
        value: 105,
        label: '到仓出仓'
      },
      {
        value: 106,
        label: '出仓完成'
      },
      {
        value: 107,
        label: 'HOLD'
      }
    ],
    [
      {
        value: 1000,
        label: '拒绝'
      },
      {
        value: 1,
        label: '作废'
      }
    ],
    [
      {
        value: 10000,
        label: '完结'
      }
    ]
  ],
  billAirSubtabOptions: [
    [
      {
        value: 0,
        label: '未发送'
      },
      {
        value: 1,
        label: '待确认'
      },
      {
        value: 1000,
        label: '拒绝'
      }
    ],
    [
      {
        value: 2,
        label: '待发预报'
      },
      {
        value: 3,
        label: '预报已发送'
      },
      {
        value: 4,
        label: '预报已接受'
      },
      {
        value: 5,
        label: '税金待确认'
      },
      {
        value: 6,
        label: '清关中'
      },
      {
        value: 7,
        label: '查验中'
      },
      {
        value: 8,
        label: '已放行'
      }
    ],
    [
      {
        value: 100,
        label: '运输中'
      },
      {
        value: 105,
        label: '到仓出仓'
      },
      {
        value: 106,
        label: '出仓完成'
      },
      {
        value: 107,
        label: 'HOLD'
      }
    ],
    [
      {
        value: 1000,
        label: '拒绝'
      },
      {
        value: 1,
        label: '作废'
      }
    ],
    [
      {
        value: 10000,
        label: '完结'
      }
    ]
  ],
  clearenceTypeOptions: [
    {
      value: 0,
      label: '自清'
    },
    {
      value: 1,
      label: '平台清'
    }
  ],
  pickUpTypeOptions: [
    {
      value: 0,
      label: '自提'
    },
    {
      value: 1,
      label: '直送'
    },
    {
      value: 2,
      label: '回仓'
    }
  ],
  isfStatusOptions: [
    {
      value: 0,
      label: '未填报'
    },
    {
      value: 1,
      label: '未发送',
      permissson: 'WFS'
    },
    {
      value: 2,
      label: '未接受',
      permissson: 'WJS'
    },
    {
      value: 3,
      label: '已接受',
      permissson: 'YJS'
    },
    {
      value: 4,
      label: 'OnFile',
      permissson: 'ONFILE'
    },
    {
      value: 5,
      label: 'NotOnFile',
      permissson: 'NOTONFILE'
    },
    {
      value: 6,
      label: '取消',
      permissson: 'QX'
    },
    {
      value: -1,
      label: 'NA'
    }
  ],
  bondTypeOptions: [
    {
      value: 0,
      label: 'AnnualBond'
    },
    {
      value: 1,
      label: 'SingleBond'
    }
  ],
  billNumberTypeOptions: [
    {
      value: 0,
      label: 'Regular bill/staight bill'
    },
    {
      value: 1,
      label: 'House bill'
    }
  ],
  billShipOptOptions: [
    [
      {
        name: '详情',
        permissson: 'detail',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function() {
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function() {
          return true
        }
      }
    ],
    [
      {
        name: '详情',
        permissson: 'detail_a',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] }
      },
      {
        name: '装载',
        permissson: 'loadOrder',
        class: { link: 'link' },
        event: { eventName: 'loadOrder', options: [] },
        vIf: function(active, billPickUpType, isPushFMS, isOnLoad) {
          if ((active === 0 || active === 1) && isOnLoad) return true
          return false
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_a',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] }
      },
      {
        name: '确认',
        permissson: 'sure',
        class: { link: 'link' },
        event: { eventName: 'checkBill', options: [0] },
        vIf: function(active) {
          if (active === 1) return true
          return false
        }
      },
      {
        name: '拒绝',
        permissson: 'refuse',
        class: { link: 'link', refuse: 'refuse' },
        event: { eventName: 'checkBill', options: [1] },
        vIf: function(active) {
          if (active === 1) return true
          return false
        }
      }
    ],
    [
      {
        name: '审核',
        permissson: 'audit_b',
        class: { link: 'link2' },
        event: { eventName: 'audit', options: [] },
        vIf: function(active) {
          if (active === 3) return true
          return false
        }
      },
      {
        name: '推送GGT',
        permissson: 'ggt',
        class: { link: 'link2' },
        event: { eventName: 'ggt', options: [] },
        vIf: function(active) {
          if (active === 4) return true
          return false
        }
      },
      {
        name: '详情',
        permissson: 'detail_b',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function(active) {
          if (active === 8 || active === 3) return false
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_b',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function(active) {
          if (active === 8 || active === 3) return false
          return true
        }
      },
      {
        name: '上传附件',
        permissson: 'upload_b',
        class: { link: 'link' },
        event: { eventName: 'uploadAttachment', options: [] },
        vIf: function(active) {
          if (active === 8 || active === 3 || active === 4) return false
          return true
        }
      },
      {
        name: '上传税金单',
        permissson: 'upload_b',
        class: { link: 'link' },
        event: { eventName: 'uploadAttachment', options: [] },
        vIf: function(active) {
          if (active === 4) return true
          return false
        }
      },
      {
        name: '完结',
        permissson: 'over_c',
        class: { link: 'link2' },
        event: { eventName: 'over', options: [] },
        vIf: function(active, billPickUpType = 1) { // billPickUpType 提货方式
          if (active === 8 && !billPickUpType) return true
          return false
        }
      },
      // {
      //   name: '查看清关轨迹',
      //   permissson: 'ship_sTrack_b',
      //   class: { link: 'link2' },
      //   event: { eventName: 'showTrack', options: [0] },
      //   vIf: function(active) {
      //     if (active === 6 || active === 7 || active === 8) return true
      //     return false
      //   }
      // },
      {
        name: '更多',
        vIf: function(active) {
          if (active === 8 || active === 3) return true
          return false
        },
        children: [
          {
            name: '提单详情',
            permissson: 'detail_b',
            class: { link: 'link' },
            event: { eventName: 'detail', options: [] }
          },
          {
            name: '跟进记录',
            permissson: 'uploadLog_b',
            class: { link: 'link', newMessage: 'new-message' },
            event: { eventName: 'updateLog', options: [] }
          },
          {
            name: '上传附件',
            permissson: 'upload_b',
            class: { link: 'link' },
            event: { eventName: 'uploadAttachment', options: [] }
          }
        ]
      }
    ],
    [
      {
        name: '到港',
        permissson: 'arrivalPort_c',
        class: { link: 'link2' },
        event: { eventName: 'showDateTime', options: ['到港'] },
        vIf: function(active) {
          if (active === 100) return true
          return false
        }
      },
      {
        name: '卸船',
        permissson: 'DischargeShip_c',
        class: { link: 'link2' },
        event: { eventName: 'showDateTime', options: ['卸船'] },
        vIf: function(active) {
          if (active === 101) return true
          return false
        }
      },
      {
        name: '预约',
        permissson: 'subscribe_c',
        class: { link: 'link2' },
        event: { eventName: 'showAppointment', options: [] },
        vIf: function(active) {
          if (active === 102) return true
          return false
        }
      },
      {
        name: '到达',
        permissson: 'arrive',
        class: { link: 'link2' },
        event: { eventName: 'arrive', options: [] },
        vIf: function(active) {
          if (active === 103) return true
          return false
        }
      },
      {
        name: '推送FMS',
        permissson: 'fms',
        class: { link: 'link2' },
        event: { eventName: 'fms', options: [] },
        vIf: function(active, billPickUpType = 1, isPushFMS) {
          console.log('billPickUpType', billPickUpType)
          if ((active === 100 || active === 101 || active === 102) && !isPushFMS) return true
          return false
        }
      },
      {
        name: '装载',
        permissson: 'loadOrder',
        class: { link: 'link2' },
        event: { eventName: 'loadOrder', options: [] },
        vIf: function(active) {
          if (active === 100 || active === 101 || active === 102) return true
          return false
        }
      },
      {
        name: 'FMS跟进记录',
        permissson: 'fms',
        class: { link: 'link2' },
        event: { eventName: 'fmsRemark', options: [] },
        vIf: function(active, billPickUpType = 1, isPushFMS) { // isFMS是否已推送
          console.log('billPickUpType', billPickUpType)
          if ((active === 100 || active === 101 || active === 102) && isPushFMS) return true
          return false
        }
      },
      // {
      //   name: '查看船司轨迹',
      //   permissson: 'ship_sTrack_c',
      //   class: { link: 'link2' },
      //   event: { eventName: 'showTrack', options: [1] },
      //   vIf: function(active) {
      //     if (active === 100 || active === 101 || active === 102) return true
      //     return false
      //   }
      // },
      {
        name: '到仓',
        permissson: 'ArrivalWarehouse',
        class: { link: 'link2' },
        event: { eventName: 'showWarehouse', options: ['0'] },
        vIf: function(active) {
          if (active === 105 || active === 106) return true
          return false
        }
      },
      {
        name: '完结',
        permissson: 'over_c',
        class: { link: 'link2' },
        event: { eventName: 'over', options: [] },
        vIf: function(active) {
          if (active === 106) return true
          return false
        }
      },
      {
        name: '出仓',
        permissson: 'OutWarehouse',
        class: { link: 'link2' },
        event: { eventName: 'showWarehouse', options: ['1'] },
        vIf: function(active) {
          if (active === 105) return true
          return false
        }
      },
      {
        name: 'release',
        permissson: 'release',
        class: { link: 'link2' },
        event: { eventName: 'release', options: ['1'] },
        vIf: function(active) {
          if (active === 107) return true
          return false
        }
      },
      {
        name: '更多',
        children: [
          {
            name: '提单详情',
            permissson: 'detail_c',
            class: { link: 'link' },
            event: { eventName: 'detail', options: [] }
          },
          {
            name: '跟进记录',
            permissson: 'uploadLog_c',
            class: { link: 'link', newMessage: 'new-message' },
            event: { eventName: 'updateLog', options: [] }
          },
          {
            name: '上传附件',
            permissson: 'upload_c',
            class: { link: 'link' },
            event: { eventName: 'uploadAttachment', options: [] }
          }
        ]
      }
    ],
    [
      {
        name: '详情',
        permissson: 'detail_d',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function() {
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_d',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function() {
          return true
        }
      }
    ],
    [
      {
        name: '详情',
        permissson: 'detail_e',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function() {
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_e',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function() {
          return true
        }
      }
    ]
  ],
  billAirOptOptions: [
    [
      {
        name: '详情',
        permissson: 'detail',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function() {
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function() {
          return true
        }
      }
    ],
    [
      {
        name: '详情',
        permissson: 'detail_a',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] }
      },
      {
        name: '装载',
        permissson: 'loadOrder',
        class: { link: 'link' },
        event: { eventName: 'loadOrder', options: [] },
        vIf: function(active, billPickUpType, isPushFMS, isOnLoad) {
          if ((active === 0 || active === 1) && isOnLoad) return true
          return false
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_a',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] }
      },
      {
        name: '确认',
        permissson: 'sure',
        class: { link: 'link' },
        event: { eventName: 'checkBill', options: [0] },
        vIf: function(active) {
          if (active === 1) return true
          return false
        }
      },
      {
        name: '拒绝',
        permissson: 'refuse',
        class: { link: 'link', refuse: 'refuse' },
        event: { eventName: 'checkBill', options: [1] },
        vIf: function(active) {
          if (active === 1) return true
          return false
        }
      }
    ],
    [
      {
        name: '审核',
        permissson: 'audit_b',
        class: { link: 'link' },
        event: { eventName: 'audit', options: [] },
        vIf: function(active) {
          if (active === 3) return true
          return false
        }
      },
      {
        name: '推送GGT',
        permissson: 'ggt',
        class: { link: 'link2' },
        event: { eventName: 'ggt', options: [] },
        vIf: function(active) {
          if (active === 4) return true
          return false
        }
      },
      {
        name: '详情',
        permissson: 'detail_b',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function(active) {
          if (active === 6 || active === 7 || active === 8) return false
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_b',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function(active) {
          if (active === 6 || active === 7 || active === 8) return false
          return true
        }
      },
      {
        name: '上传附件',
        permissson: 'upload_b',
        class: { link: 'link' },
        event: { eventName: 'uploadAttachment', options: [] },
        vIf: function(active) {
          if (active === 4 || active === 6 || active === 7 || active === 8) return false
          return true
        }
      },
      {
        name: '上传税金单',
        permissson: 'upload_b',
        class: { link: 'link' },
        event: { eventName: 'uploadAttachment', options: [] },
        vIf: function(active) {
          if (active === 4) return true
          return false
        }
      },
      {
        name: '完结',
        permissson: 'over_c',
        class: { link: 'link2' },
        event: { eventName: 'over', options: [] },
        vIf: function(active) {
          if (active === 8) return true
          return false
        }
      },
      {
        name: '查看清关轨迹',
        permissson: 'ship_sTrack_b',
        class: { link: 'link2' },
        event: { eventName: 'showTrack', options: [0] },
        vIf: function(active) {
          if (active === 6 || active === 7 || active === 8) return true
          return false
        }
      },
      {
        name: '更多',
        vIf: function(active) {
          if (active === 6 || active === 7 || active === 8) return true
          return false
        },
        children: [
          {
            name: '提单详情',
            permissson: 'detail_b',
            class: { link: 'link' },
            event: { eventName: 'detail', options: [] }
          },
          {
            name: '跟进记录',
            permissson: 'uploadLog_b',
            class: { link: 'link', newMessage: 'new-message' },
            event: { eventName: 'updateLog', options: [] }
          },
          {
            name: '上传附件',
            permissson: 'upload_b',
            class: { link: 'link' },
            event: { eventName: 'uploadAttachment', options: [] }
          }
        ]
      }
    ],
    [
      {
        name: '到达',
        permissson: 'arrive',
        class: { link: 'link2' },
        event: { eventName: 'showDateTime', options: ['到达'] },
        vIf: function(active) {
          if (active === 100) return true
          return false
        }
      },
      {
        name: '到仓',
        permissson: 'ArrivalWarehouse',
        class: { link: 'link2' },
        event: { eventName: 'showWarehouse', options: ['0'] },
        vIf: function(active) {
          if (active === 105 || active === 106) return true
          return false
        }
      },
      {
        name: '完结',
        permissson: 'over_c',
        class: { link: 'link2' },
        event: { eventName: 'over', options: [] },
        vIf: function(active) {
          if (active === 106) return true
          return false
        }
      },
      {
        name: '出仓',
        permissson: 'OutWarehouse',
        class: { link: 'link2' },
        event: { eventName: 'showWarehouse', options: ['1'] },
        vIf: function(active) {
          if (active === 105) return true
          return false
        }
      },
      {
        name: 'release',
        permissson: 'release',
        class: { link: 'link2' },
        event: { eventName: 'release', options: ['1'] },
        vIf: function(active) {
          if (active === 107) return true
          return false
        }
      },
      {
        name: '装载',
        permissson: 'loadOrder',
        class: { link: 'link2' },
        event: { eventName: 'loadOrder', options: [] },
        vIf: function(active,) {
          if (active === 100 || active === 101 || active === 102 || active === 105) return true
          return false
        }
      },
      {
        name: '推送FMS',
        permissson: 'fms',
        class: { link: 'link2' },
        event: { eventName: 'fms', options: [] },
        vIf: function(active, billPickUpType = 1, isPushFMS) {
          console.log('billPickUpType', billPickUpType)
          if (active === 100 && !isPushFMS) return true
          return false
        }
      },
      {
        name: 'FMS跟进记录',
        permissson: 'fms',
        class: { link: 'link2' },
        event: { eventName: 'fmsRemark', options: [] },
        vIf: function(active, billPickUpType = 1, isPushFMS) { // isFMS是否已推送
          console.log('billPickUpType', billPickUpType)
          if (active === 100 && isPushFMS) return true
          return false
        }
      },
      {
        name: '更多',
        children: [
          {
            name: '提单详情',
            permissson: 'detail_c',
            class: { link: 'link' },
            event: { eventName: 'detail', options: [] }
          },
          {
            name: '跟进记录',
            permissson: 'uploadLog_c',
            class: { link: 'link', newMessage: 'new-message' },
            event: { eventName: 'updateLog', options: [] }
          },
          {
            name: '上传附件',
            permissson: 'upload_c',
            class: { link: 'link' },
            event: { eventName: 'uploadAttachment', options: [] }
          }
        ]
      }
    ],
    [
      {
        name: '详情',
        permissson: 'detail_d',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function() {
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_d',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function() {
          return true
        }
      }
    ],
    [
      {
        name: '详情',
        permissson: 'detail_e',
        class: { link: 'link' },
        event: { eventName: 'detail', options: [] },
        vIf: function() {
          return true
        }
      },
      {
        name: '跟进记录',
        permissson: 'uploadLog_e',
        class: { link: 'link', newMessage: 'new-message' },
        event: { eventName: 'updateLog', options: [] },
        vIf: function() {
          return true
        }
      }
    ]
  ],
  workBillCustomerOptions: [
    {
      value: 0,
      label: '操作'
    },
    {
      value: 1,
      label: '财务'
    },
    {
      value: 2,
      label: '客服'
    },
    {
      value: 3,
      label: '销售'
    }
  ],
  priorityLevelOptions: [
    {
      value: 0,
      label: '一般'
    },
    {
      value: 1,
      label: '重要紧急'
    },
    {
      value: 2,
      label: '重要不紧急'
    }
  ],
  workBillStatusOptions: [
    {
      value: 0,
      label: '新增'
    },
    {
      value: 1,
      label: '处理中'
    },
    {
      value: 2,
      label: '通过'
    },
    {
      value: 3,
      label: '关闭'
    },
    {
      value: 4,
      label: '撤回'
    }
  ],
  workBillRemarkPermissionOptions: [
    {
      value: 0,
      label: '所有'
    },
    {
      value: 1,
      label: '对内'
    },
    {
      value: 2,
      label: '对外'
    }
  ],
  classificationOptions: [
    {
      value: 0,
      label: '商业'
    },
    {
      value: 1,
      label: 'MIXED'
    },
    {
      value: 2,
      label: '住宅'
    },
    {
      value: 3,
      label: '未知'
    }
  ],
  sendWayOptions: [{
    value: 0,
    label: 'UPS'
  }, {
    value: 1,
    label: 'FedEx'
  }, {
    value: 2,
    label: '亚马逊仓'
  }, {
    value: 3,
    label: '私仓'
  }, {
    value: 4,
    label: '自提'
  }, {
    value: 100,
    label: '其它'
  }],
  FMSMBLSendWayTypeOptions: [{
    value: 0,
    label: '直送快递商'
  }, {
    value: 1,
    label: '直送亚马逊仓'
  }, {
    value: 2,
    label: '直送私仓'
  }, {
    value: 3,
    label: '回仓自提'
  }, {
    value: 4,
    label: '回仓快递'
  }, {
    value: 5,
    label: '回仓卡派亚马逊'
  }, {
    value: 6,
    label: '回仓卡派私仓'
  }],
  classificationHoldOption: [{
    value: 100,
    label: '所有'
  }, {
    value: 101,
    label: '客户'
  }, {
    value: 102,
    label: '后台'
  }],
  scanStatusOption: [
    {
      value: 0,
      label: '未扫描'
    },
    {
      value: 1,
      label: '成功'
    },
    {
      value: 2,
      label: '失败'
    }
  ],
  billHoldTypeOption: [
    {
      value: 0,
      label: '主提单'
    },
    {
      value: 1,
      label: '分提单'
    },
    {
      value: 2,
      label: '柜子'
    },
    {
      value: 3,
      label: '订单'
    }
  ],
  sandWayTypeOptions: [
    {
      value: 0,
      label: '整柜拦截'
    },
    {
      value: 1,
      label: '订单暂扣'
    },
    {
      value: 2,
      label: '订单放件'
    },
    {
      value: 3,
      label: '订单拦截改自提'
    },
    {
      value: 4,
      label: '订单拦截换标'
    },
    {
      value: 5,
      label: '拍照'
    },
    {
      value: 6,
      label: '整柜放件'
    },
    {
      value: 1000,
      label: '其他'
    },
    {
      value: 1001,
      label: '其他扣件'
    }],
  fileTypeOption: [
    {
      value: 0,
      label: '提单文件'
    },
    {
      value: 1,
      label: 'AN'
    },
    {
      value: 2,
      label: 'DO'
    },
    {
      value: 3,
      label: 'ISA'
    },
    {
      value: 4,
      label: 'POD'
    },
    {
      value: 5,
      label: '提单发票'
    },
    {
      value: 6,
      label: '后台发票'
    },
    {
      value: 7,
      label: '税金单'
    },
    {
      value: 8,
      label: 'BOL'
    },
    {
      value: 9,
      label: 'ISF'
    },
    {
      value: 10,
      label: '费用证明'
    },
    {
      value: 11,
      label: '少货证明'
    },
    {
      value: 12,
      label: '拆柜清单'
    },
    {
      value: 99,
      label: '其他'
    }
  ],
  claimPayStatusOption: [
    {
      value: 0,
      label: '待创建人审批'
    },
    {
      value: 5,
      label: '待主管审批'
    },
    {
      value: 10,
      label: '待经理审批'
    },
    {
      value: 15,
      label: '待总监审批'
    },
    {
      value: 20,
      label: '待财务审批'
    },
    {
      value: 25,
      label: '审批结束'
    },
    {
      value: -1,
      label: '审批驳回'
    }
  ],
  claimPayDefrayTypeOption: [
    {
      value: 0,
      label: '清提'
    },
    {
      value: 1,
      label: '派送'
    },
    {
      value: 2,
      label: '派送（预付）'
    }
  ],
  claimPayDefrayStatusOption: [
    {
      value: 0,
      label: '未申付账单查询'
    },
    {
      value: 1,
      label: '未支付'
    },
    {
      value: 2,
      label: '已支付'
    }
  ],
  unitOption: [
    {
      value: 0,
      label: '个'
    },
    {
      value: 1,
      label: '分单'
    },
    {
      value: 2,
      label: '柜子'
    },
    {
      value: 3,
      label: '20GP'
    },
    {
      value: 4,
      label: '40HQ'
    },
    {
      value: 5,
      label: '45HQ'
    }
  ],
  checkListStatusOptions: [
    {
      value: 10000,
      label: '全部',
      permissson: 'QB'
    },
    {
      value: 0,
      label: '查验中',
      permissson: 'CYZ'
    },
    {
      value: 1,
      label: '部分放行',
      permissson: 'BFFX'
    },
    {
      value: 2,
      label: '全部放行',
      permissson: 'QBFX'
    },
    {
      value: 3,
      label: '完结',
      permissson: 'WJ'
    }],
  cardInquiryStatusOptions: [
    {
      value: -1,
      label: '全部',
      permissson: 'QB'
    },
    {
      value: 0,
      label: '未报价',
      permissson: 'WBJ'
    },
    {
      value: 5,
      label: '已报价',
      permissson: 'YBJ'
    },
    {
      value: 10,
      label: '已使用',
      permissson: 'YSY'
    }
  ],
  palletOptions: [
    { label: '48*40', value: '48*40' },
    { label: '48*44', value: '48*44' },
    { label: '自定义', value: '自定义' }
  ],
  unitTypeOptions: [
    { label: 'LB/IN', value: 0 },
    { label: 'KG/CM', value: 1 }
  ],
  containertypeOptions: [
    {
      value: '20GP',
      label: '20GP'
    },
    {
      value: '40GP',
      label: '40GP'
    },
    {
      value: '40HQ',
      label: '40HQ'
    },
    {
      value: '40RH',
      label: '40RH'
    },
    {
      value: '40RF',
      label: '40RF'
    },
    {
      value: '40NOR',
      label: '40NOR'
    },
    {
      value: '45HC',
      label: '45HC'
    },
    {
      value: '45HQ',
      label: '45HQ'
    },
    {
      value: '53HQ',
      label: '53HQ'
    }
  ],
  AgencyTypeOptions: [
    {
      value: 0,
      label: '充值审核'
    },
    {
      value: 1,
      label: '订单费用估算异常'
    },
    {
      value: 2,
      label: '提单待确认'
    },
    {
      value: 3,
      label: '预报已发送'
    },
    {
      value: 6,
      label: '税金待确认'
    },
    {
      value: 8,
      label: '提单文件待上传'
    },
    {
      value: 10,
      label: '待发预报'
    }
  ],
  payableTypeOptions: [
    {
      value: 0,
      label: '银付'
    },
    {
      value: 1,
      label: '对冲'
    },
    {
      value: 10,
      label: '其他'
    }
  ],
  cookieKey: 'accessToken' // cookie标识
}
