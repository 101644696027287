<template>
  <div class="container">
    <div class="header">
      <Header class="header-content"></Header>
    </div>
    <div class="main">
      <div class="nav" :style="{ width: isCollapse ? '60px' : '160px' }">
        <Nav></Nav>
      </div>
      <div
        class="content"
        :style="{
          width: isCollapse ? 'calc(100% - 60px)' : 'calc(100% - 160px)',
          marginLeft: isCollapse ? '60px' : '160px',
        }"
      >
        <div class="alive-item">
          <alive-nav></alive-nav>
        </div>
        <div class="page">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Nav from './components/Nav.vue'
import { mapGetters } from 'vuex'
import AliveNav from './components/AliveNav.vue'
import * as signalR from '@aspnet/signalr'
import feepowerIcon from '@/assets/feepowerIcon.png'
import config from '@/utils/config'
export default {
  components: { Header, Nav, AliveNav },
  computed: {
    ...mapGetters(['isCollapse'])
  },
  created() {
    this.init()
  },
  methods: {
    // 唤起浏览器弹框 前提浏览器得允许弹框通知显示
    showMsg(msg) {
      const that = this
        // 如果支持window.Notification 并且 许可不是拒绝状态
        if (window.Notification && Notification.permission !== 'denied') {
          // Notification.requestPermission这是一个静态方法，作用就是让浏览器出现是否允许通知的提示
          Notification.requestPermission(function(status) {
            // 如果状态是同意
            if (status === 'granted') {
              const m = new Notification(msg.title, {
                body: msg.body, // 消息体内容
                icon: feepowerIcon // 消息图片
              })
              // 点击当前消息内容
              m.onclick = function() {
                // 点击当前消息提示框后，跳转到当前页面
                // that.$router.push({ path: '/Reception/chatpage' })
                window.focus()
              }
              // 点击关闭消息提示框
              m.onclose = function() {
                m.close()
              }
            } else {
              that.$notify('当前浏览器不支持弹出消息')
            }
          })
        }
    },
    init() {
      // 构建服务通讯连接对象
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`/backEnd/Hub/${config.supplyCode}/JobBenchHub`, {
        // .withUrl(`/Hub/JobBenchHub`, {
          // 这里可以请求报文头
          skipNegotiation: true, // 关键
          transport: signalR.HttpTransportType.WebSockets // 关键
        })
        .configureLogging(signalR.LogLevel.Information)
        .build()

      // 正式建立连接
      this.connection
        .start()
        .then(function() {
          console.log('正式建立连接')
        })
        .catch(function(err) {
          return console.error(err)
        })

      //   // 重新连接监听
      //   connection.on('Show', res => {
      //     // 可以做相关业务逻辑
      //     console.log('signalr 来了', message)
      // })
      this.connection.on('UserMessage', (message) => {
        console.log('signalr 来了', message)
        const msg = JSON.parse(message)
        switch (msg.type) {
          case 0:
            // 消息
            this.$store.commit('SET_ISNEWMSG', true)
            this.showMsg({
              title: '有新消息',
              body: msg.description
            })
            break
          case 1:
            // 代办
            this.$store.commit('SET_ISNEWUPCOME', true)
            this.showMsg({
              title: '有新待办',
              body: msg.description
            })
            break
          case 2:
            // 工单
            // this.$store.commit('CHANGE_UPCOME_DRAWER', true)
            break
          default:
            break
        }
      })

      // 监听广播的消息(费用试算)
      this.connection.on('UserSchedule', (message) => {
        console.log('signalr 来了', message)
        const msg = JSON.parse(message)
        switch (msg.type) {
          case 0:
            // 消息
            this.$store.commit('SET_ISNEWMSG', true)
            this.showMsg({
              title: '有新消息',
              body: msg.description
            })
            break
          case 1:
            // 代办
            this.$store.commit('SET_ISNEWUPCOME', true)
            this.showMsg({
              title: '有新待办',
              body: msg.description
            })
            break
          case 2:
            // 工单
            // this.$store.commit('CHANGE_UPCOME_DRAWER', true)
            break
          default:
            break
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .header {
    width: 100%;
    height: 42px;
    background: #2395f3;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: relative;
    &::after {
      content: " ";
      background: url("../assets/images/loyout/bg.png") no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 42px;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .header-content {
      position: absolute;
      z-index: 2;
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 42px);
    display: flex;
    position: relative;
    .nav {
      width: 160px;
      height: 100%;
      width: 160px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      z-index: 999;
    }
    .content {
      width: calc(100% - 160px);
      height: 100%;
      margin-left: 160px;
    }
    .alive-item {
      width: 100%;
      height: 42px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      overflow: hidden;
    }
    .page {
      height: calc(100% - 62px);
      width: calc(100% - 20px);
      background: #f7f7f7;
      padding: 10px;
    }
  }
}
</style>
