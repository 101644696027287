<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import autofit from 'vue-autofit'
// export default {
//   mounted() {
//     autofit.init({
//       designHeight: 929,
//       designWidth: 1920,
//       renderDom: '#app',
//       resize: true
//     })
//   }
// }
</script>
<style lang="less">
#app {
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
.el-autocomplete-suggestion {
  li {
    line-height: 30px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
