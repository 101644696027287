<template>
  <div class="resister-content">
    <Tab :tabData="tabData" :active="active"> </Tab>
    <div class="form">
      <el-form ref="form" :model="form">
        <el-form-item prop="username" style="position: relative">
          <el-input
            v-model="form.userName"
            type="text"
            placeholder="请输入用户名（特殊字符不可输入）"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="username" style="position: relative">
          <el-input
            v-model="form.number"
            type="text"
            :placeholder="`${form.type ? '请输入邮箱' : '请输入手机号'}`"
          >
            <el-select slot="prepend" v-model="form.type" style="color:#25396F;font-size: 16px;" placeholder="请选择">
              <el-option label="手机号" :value="0"></el-option>
              <el-option label="邮箱" :value="1"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="username" style="position: relative">
          <div class="ecode">
            <el-input
              v-model="form.passCode"
              type="text"
              maxlength="20"
              style="width: 260px"
              placeholder="请输入验证码"
            >
            </el-input>
            <el-button
              :disabled="disabled"
              class="btn"
              style="margin-left: 16px; width: 124px"
              @click="sendECode"
            >{{ sendCode }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item prop="password" style="position: relative">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="请输入密码"
          >
          </el-input>
        </el-form-item>
        <p class="des">
          密码长度6-32个字符，字母/数字/下划线组成(必须含有字母和数字)
        </p>
        <el-button
          class="registe-btn"
          type="primary"
          :loading="loading"
          @click="reigster"
        >注册</el-button
        >
        <p class="goLogin" @click="goLogin">已有账号？返回登录</p>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tab from './components/Tab.vue'
import { mapGetters } from 'vuex'
import { sendPassCode, register } from '@/api/system'
import { getKey } from '@/utils/common'
import { Encrypt } from '@/utils/secret'
import { isValidPhone, isValidEmail, isValidPwd } from '@/utils/validate'
export default {
  name: 'Resister',
  components: { Tab },
  data() {
    return {
      tabData: ['注册'],
      active: 1,
      form: {
        password: '',
        userName: '',
        number: '',
        type: 0,
        passCode: '',
        fingerId: ''
      },
      sendCode: '获取验证码',
      disabled: false,
      loading: false,
      key: '',
      isValid: false
    }
  },
  computed: {
    ...mapGetters(['sign'])
  },
  async mounted() {
    const key = await getKey()
    this.fingerId = key
    if (!this.sign) {
      this.$store.dispatch('getSign', key)
    }
  },
  methods: {
    reigster() {
      if (
        !(
          this.form.password &&
          this.form.userName &&
          this.form.number &&
          this.form.passCode
        )
      ) {
        this.$message.error(`未填写完整信息`)
        return
      }
      if (!isValidPwd(this.form.password)) {
        this.$message.error(
          `密码长度6-32个字符，字母/数字/下划线组成(必须含有字母和数字)`
        )
        return
      }
      const obj = {
        ...this.form,
        password: Encrypt(this.form.password, this.sign),
        fingerId: this.fingerId
      }
      register(obj).then((res) => {
        if (res.success) {
          this.$message.success(`注册成功! 用户名:${res.data}`)
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    sendECode() {
      if (!this.form.number) {
        this.$message.error(`未填写${this.form.type ? '手机号' : '邮箱'}`)
        return
      }
      if (!this.form.type) {
        if (!isValidPhone(this.form.number)) {
          this.$message.error('请填写正确格式的手机')
          return
        }
      } else {
        if (!isValidEmail(this.form.number)) {
          this.$message.error('请填写正确格式的邮箱')
          return
        }
      }
      const param = {
        number: this.form.number,
        passCodeType: 1, // 0: 登录 1：注册 2：忘记密码 3：核验 4 修改
        sendType: this.form.type
      }
      sendPassCode(param).then((res) => {
        if (res.success) {
          this.disabled = true
          let time = 60
          const timer = setInterval(() => {
            if (time > 0) {
              this.sendCode = `${time}秒重发`
              time--
            } else {
              clearInterval(timer)
              this.disabled = false
              this.sendCode = '发送验证码'
              time = 60
            }
          }, 1000)
        }
      })
    },
    goLogin() {
        this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.resister-content {
  width: calc(100% - 100px);
  height: calc(100% - 80px);
  margin: 40px 50px;
  .form {
    width: 100%;
    height: auto;
    margin-top: 39px;
    .btn {
      width: 124px;
      height: 56px;
      background: #F8FBFF;
      font-size: 16px;
      font-weight: 400;
      color: #2395F3;
      border: 0;
    }
    .des {
      margin-top: -14px;
      margin-left: 16px;
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: #b8c3de;
      line-height: 16px;
      text-align: left;
    }
    .registe-btn {
      width: 100%;
      height: 56px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-top: 32px;
    }
    .goLogin {
      width: 144px;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #25396f;
      line-height: 21px;
      margin: 38px auto 0;
      cursor: pointer;
      &:hover {
        color:  #2395f3;
      }
    }
  }
  /deep/.el-select .el-input {
    width: 100px;
  }
  /deep/ .el-input-group__prepend {
    position: relative;
    border: 0;
    &::after {
      content: '';
      position: absolute;
      height: 16px;
      border-left: 1px solid #B8C3DE;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  /deep/ .el-input__inner, /deep/ .el-select .el-input__inner {
    height: 56px;
    background: #f8fbff;
    border: 0;
    color: #25396F;
  }
  /deep/ .el-input-group__prepend {
    background: #f8fbff;
  }
  /deep/ .el-input__inner::-webkit-input-placeholder{
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #B8C3DE;
  }
}
</style>
