/* eslint-disable no-undef */
const CryptoJS = require('crypto-js') // 引用AES源码js
// o68FY64-*bM7c5
// const key = CryptoJS.enc.Utf8.parse('3376E34B3B0A7F1C') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('PtdQDdeb3ga7O9uS') // 十六位十六进制数作为密钥偏移量

// 解密方法
function Decrypt(word, key) {
    // const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
    const srcs = CryptoJS.enc.Base64.parse(word)
    const decrypt = CryptoJS.AES.decrypt(srcs, CryptoJS.enc.Utf8.parse(key), { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
}

// 加密方法
function Encrypt(word, key) {
    const srcs = CryptoJS.enc.Utf8.parse(word)
    const encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(key), { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

export {
    Decrypt,
    Encrypt
}
