/* eslint-disable no-unused-vars */
// 公用的JS 方法
import Fingerprint2 from 'fingerprintjs2'
import asyncRoutes from '../router/menuLists'
import axios from 'axios'
import config from './config'
import { Encrypt } from './secret'
import { Message } from 'element-ui'
/**
 * 树 转 列表
 * 广度优先，先进先出
 * @param {Array} tree 树状数据
 * @param {String} childKey children的key
 */

export function treeToList(tree, childKey = 'childMenuItems') {
  const stack = tree.concat([])
  const data = []
  while (stack.length !== 0) {
    // 从stack中拿出来分析
    const shift = stack.shift() // stack.pop()  先进后出
    data.push(shift)
    const children = shift[childKey]
    if (children) {
      for (let i = 0; i < children.length; i++) {
        // 把数据放入stack中
        stack.push(children[i])
      }
    }
  }
  return data
}
export function flatten(arr) {
  return arr.reduce(function(prev, next) {
      return prev.concat(Array.isArray(next) ? flatten(next) : next)
  }, [])
}
export function getKey() {
  return new Promise((resolve) => {
    Fingerprint2.get((components) => {
      const values = components.map(function(component, index) {
        if (index === 0) {
          // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
          return component.value.replace(/\bNetType\/\w+\b/, '')
        }
        return component.value
      })
      // 生成最终id murmur
      const murmur = Fingerprint2.x64hash128(values.join(''), 31)
      resolve(murmur)
    })
  })
}
/**
 * 返回第一个路由
 * @param {Array} menuPermissions 权限菜单
 */
export function firstPath(menuPermissions) {
  let code
  if (menuPermissions[0].children) {
    code = menuPermissions[0].children[0].code
  } else {
    code = menuPermissions[0].code
  }
  console.log(code, 'code')
  let path = ''
  asyncRoutes.map((item) => {
    // TODO 第一层 第三层未做
    item.children &&
      item.children.map((b) => {
        if (b.meta.code === code) {
          path = item.path + '/' + b.path
        }
      })
  })
  return path
}
/**
 * 返回对应界面的按钮权限组
 * @param {Array} code 菜单编码
 * @param {Array} routers 权限路由
 */
export function permissions(code, routers) {
  const menuList = treeToList(routers, 'children')
  const menu = menuList.find((a) => a.code === code)
  return menu
}

export function tabPermissions(code, activeTabCode, routers) {
  const menuList = treeToList(routers, 'children')
  const menu = menuList.find((a) => a.code === code)
  const tabMenu = menu.children.find(a => a.code === activeTabCode)
  return tabMenu
}

/**
 * 返回第一个路由
 * @param {String} code 路由code
 */
export function getRouteByCode(code) {
  // 只做三层菜单
  console.log('code', code)
  let path = ''
  try {
    asyncRoutes.forEach((a) => {
      if (a.meta.code === code) {
        path = a.path
        throw new Error()
      }
      if (a && a.children.length > 0) {
        a.children.forEach((b) => {
          console.log('codeb', code)
          if (b.meta.code === code) {
            path = a.path + '/' + b.path
            throw new Error()
          }
          if (b && b.children && b.children.length > 0) {
            b.children.forEach((c) => {
              if (c.meta.code === code) {
                path = a.path + '/' + b.path + '/' + c.path
                throw new Error()
              }
            })
          }
        })
      }
    })
    return path
  } catch (error) {
    return path
  }
}

/**
 * 近N月 - new Date()
 * @param {Number} n 月份个数
 */
export function getRecentMonthDate(n) {
  //   let result = ''
  const datenow = new Date()
  const dateend =
    datenow.getFullYear().toString() +
    '-' +
    (datenow.getMonth() + 1).toString() +
    '-' +
    datenow.getDate().toString()
  datenow.setMonth(datenow.getMonth() - n)
  const dyear = datenow.getFullYear()
  let dmonth = datenow.getMonth() + 1
  dmonth = dmonth < 10 ? 0 + dmonth : dmonth
  const dday = datenow.getDate()
  const datestart =
    dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString()
  //   result += datestart + ','
  //   result += dateend
  return [datestart, dateend]
}

/**
 * 前面多少天日期 - new Date()
 * @param {Number} n 天数
 */
export function getRecentDayDate(n = 30) {
  //   let result = ''
  const datenow = new Date()
  const thirtyDaysAgo = new Date(datenow.getTime() - n * 24 * 60 * 60 * 1000)
  const dateend =
  datenow.getFullYear().toString() +
    '-' +
    (datenow.getMonth() + 1).toString() +
    '-' +
    datenow.getDate().toString()
  // datenow.setMonth(datenow.getMonth() - n)
  const dyear = thirtyDaysAgo.getFullYear()
  let dmonth = thirtyDaysAgo.getMonth() + 1
  dmonth = dmonth < 10 ? 0 + dmonth : dmonth
  const dday = thirtyDaysAgo.getDate()
  const datestart =
    dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString()
  //   result += datestart + ','
  //   result += dateend
  return [datestart, dateend]
}

/**
 * 节流
 * @param {Function} func 执行函数
 * @param {Number} delay 指定时间
 */
export function throttle(func, delay) {
  let prev = Date.now()
  return function(...args) {
    const that = this
    const now = Date.now()
    if (now - prev >= delay) {
        func.apply(that, args)
        prev = Date.now()
    }
  }
}

/**
 * 防抖
 * @param {Function} func 执行函数
 * @param {Number} delay 指定时间
 */
export function debounce(func, delay) {
  // 闭包变量 ，用来记录保存上一次的延时器变量
  let timer
  return function(...args) {
      const that = this
      // 清除上一次延时器,取消执行
      if (timer) { clearTimeout(timer) }
      timer = setTimeout(function() {
        func.apply(that, args)
      }, delay)
  }
}

export function singleClick(fn, delay = 500) {
  let timer = null
  return function() {
    // 如果timer有值代表前面已经点击了一次  那就返回
    if (timer) {
      console.log('没有执行')
      clearTimeout(timer)
      // 初始化 要不然永远会走这个if语句
      timer = null
      return
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

/**
 * 导出方法
 * @param {String} url 请求地址
 * @param {String} method 请求方法
 * @param {Object} params 请求数据
 */
export async function derive(url, method, params) {
  try {
    // if (method)
    const reqConfig = {
      method,
      url: `/backEnd/api/${config.supplyCode}/${url}`,
      withCredentials: true,
      responseType: 'blob'
    }
    if (method === 'get') reqConfig.params = params
    if (method === 'post') reqConfig.data = params
    const result = await axios(reqConfig)
    const fileName = window.decodeURI(result.headers['content-disposition'].split('=')[2], 'UTF-8').replace(`UTF-8''`, '')
    const filestream = result.data // 返回的文件流
    const blob = new Blob([filestream], {
      type: 'application/vnd.ms-excel'
    })
    const a = document.createElement('a')
    const href = window.URL.createObjectURL(blob) // 创建下载连接
    a.href = href
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a) // 下载完移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  } catch (error) {
    Message.error('导出文件失败或不存在')
    console.error(error)
  }
}

/**
 * 设置cookie
 * @param {String} name 设置名字
 * @param {String} value 保存值
 * @param {String} day 保存天数
 * @param {String} key 密钥
 **/
export function setCookie(name, value, day, key) {
  const date = new Date()
  date.setDate(date.getDate() + day)
  if (name === 'password') {
    document.cookie = name + '=' + Encrypt(value, key) + ';expires=' + date
    return
  }
  document.cookie = name + '=' + value + ';expires=' + date
}

/**
 * 获取cookie
 * @param {String} name 设置名字
 * @param {String} key 密钥
 * @param {String} type 类型
 **/
export function getCookie(name, key, type) {
  const reg = RegExp(name + '=([^;]+)')
  const arr = document.cookie.match(reg)
  if (arr) {
    if (type === 'password') {
      // return Decrypt(arr[1], key)
      return ''
    } else {
      return arr[1]
    }
  } else {
    return ''
  }
}

/**
 * 删除cookie
 * @param {String} name 设置名字
 **/
export function delCookie(name) {
  setCookie(name, null, -1)
}
