const SET_TABSVIEW = 'SET_TABSVIEW'
const DEL_TABSVIEW = 'DEL_TABSVIEW'
const RESET_TABSVIEW = 'RESET_TABSVIEW'
const SET_CREATE_TAB = 'SET_CREATE_TAB'
const tabsview = {
  state: {
    visitedTabsView: [],
    createTabObj: {
      order: false,
      bill: false
    }
  },
  mutations: {
    [SET_TABSVIEW](state, view) {
      if (state.visitedTabsView.find((n) => n.path === view.path)) {
        return
      }
      state.visitedTabsView.push({ name: view.meta.title, path: view.path, componentName: view.name })
    },
    [DEL_TABSVIEW](state, view) {
      for (const [i, v] of state.visitedTabsView.entries()) {
        if (v.path === view.path || v.name === view.name) {
          if (state.visitedTabsView.length > 1) {
            state.visitedTabsView.splice(i, 1)
          }
        }
      }
    },
    [RESET_TABSVIEW](state, view) {
      state.visitedTabsView = view
    },
    [SET_CREATE_TAB](state, { type, bool }) {
      console.log('设置到了')
      state.createTabObj[type] = bool
    }
  },
  actions: {
    // 添加一个新的tabsView
    addVisitedTabsView({ commit }, view) {
      commit(SET_TABSVIEW, view)
    },
    // 关闭一个tabsView
    delVisitedTabsView({ commit, state }, view) {
      return new Promise((resolve) => {
        commit(DEL_TABSVIEW, view)
        resolve([...state.visitedTabsView])
      })
    }
  },
  getters: {
    visitedTabsView: state => state.visitedTabsView
  }
}

export default tabsview
