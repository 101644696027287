<template>
  <div
    class="nav-content"
    :class="[{ isCollapse: isCollapse }]"
  >
    <div class="collapse" :title="isCollapse ? '展开' : '收起'" onselectstart="return false" @click="onCollapse">
      <svg-icon v-if="!isCollapse" iconClass="menu-close" className="icon" title="收起" />
      <svg-icon v-else iconClass="menu-open" className="icon" title="展开" />
      <span class="nav-title">全部应用</span>
    </div>
    <div v-if="!isCollapse" class="menu-open">
      <div
        v-for="(item, $index) in menuLists"
        :key="$index"
        class="panel"
        onselectstart="return false"
        :class="{ active: acitvePath.indexOf(item.path) > -1 }"
      >
        <template v-if="item.children.length > 1">
          <div class="nav-item" @click="setOpenIndex(item.path)">
            <div class="nav-mark">
              <svg-icon :iconClass="item.meta.icon" className="icon" />
              <span class="nav-title">{{ item.meta.title }}</span>
            </div>
            <i
              class="el-icon-arrow-right down"
              :class="{ deg: isOpenItem === $index }"
            />
          </div>
          <div
            class="child-content"
            :class="{ 'is-open': isOpenItem === $index }"
          >
            <div
              v-for="(child, key) in item.children"
              :key="key"
              class="child-item"
              :class="{ active: acitvePath.indexOf(child.path) > -1 }"
              @click="goto(`${item.path}/${child.path}`)"
            >
              <span class="child-item-title">{{ child.meta.title }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="nav-item" @click="goto(`${item.path}${item.children[0]?.path ? `/${item.children[0]?.path}` :''}`)">
            <div class="nav-mark">
              <svg-icon :iconClass="item.meta.icon" className="icon" />
              <span class="nav-title">{{ item.meta.title }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="menu-close">
      <div v-for="(item, $index) in menuLists" :key="$index" class="panel">
        <div
          class="nav-item"
          :class="{ active: acitvePath.indexOf(item.path) > -1 }"
        >
          <svg-icon :iconClass="item.meta.icon" className="icon" />
        </div>
        <div class="child-content">
          <div class="nav-close-title">{{ item.meta.title }}</div>
          <template v-for="(child, key) in item.children">
            <template v-if="item.children.length > 1">
              <p
                :key="key"
                class="sub-menu-item"
                :class="{ active: acitvePath.indexOf(child.path) > -1 }"
                @click="goto(`${item.path}/${child.path}`)"
              >
                {{ child.meta.title }}
              </p></template
              >
            <template v-else>
              <p
                :key="key"
                class="sub-menu-item"
                :class="{ active: acitvePath.indexOf(item.path) > -1 }"
                @click="goto(`${item.path}`)"
              >
                {{ child.meta.title }}
              </p></template
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      acitvePath: '',
      isOpenItem: 1
    }
  },
  computed: {
    ...mapGetters(['isCollapse', 'menuLists'])
  },
  watch: {
    $route(route) {
      const acitvePath = route.fullPath
      this.acitvePath = acitvePath
      this.isOpenItem = null
      this.setOpenIndex(acitvePath)
    }
  },
  created() {
    const acitvePath = this.$route.fullPath
    this.acitvePath = acitvePath
    this.isOpenItem = null
    this.setOpenIndex(acitvePath)
    this.$store.commit('CHANGE_COLLAPSE', false)
  },
  methods: {
    onCollapse() {
      this.$store.commit('CHANGE_COLLAPSE', !this.isCollapse)
    },
    goto(path) {
      const query = this.$route.query
      if (query.menuCode) {
        this.$confirm('当前文档未保存，是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          iconClass: 'warning',
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          center: true
        })
          .then(() => {
            this.$router.push(path)
          })
          .catch(() => {})
      } else {
        this.$router.push(path)
      }
    },
    setOpenIndex(path) {
      const index = this.menuLists.findIndex((a) => path.indexOf(a.path) > -1)
      if (this.isOpenItem !== index) {
        this.isOpenItem = index
      } else {
        this.isOpenItem = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.nav-content {
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-align: center;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  .collapse {
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    border-bottom: 1px solid #eeeeee;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .nav-title {
    display: none;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .child-item-title {
    margin-left: 35px;
  }
  .menu-open {
    width: 100%;
    height: calc(100% - 42px);
    padding: 0 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    .panel {
      width: 100%;
      height: auto;
      margin-top: 5px;
      cursor: pointer;
    }
    .nav-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      cursor: pointer;
      .nav-mark {
        display: flex;
        align-items: center;
      }
    }
    .child-content {
      position: relative;
      left: -10px;
      width: 140px;
      height: auto;
      background: #f7f7f7;
      color: #666666;
      line-height: 50px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      z-index: 9999;
      cursor: pointer;
      display: none;
    }
    .child-item {
      width: 100%;
      height: 50px;
    }
    .down {
      transition: all 0.2s;
    }
    .deg {
      transform: rotate(90deg);
    }
    .active {
      color: #2395f3;
    }
    .is-open {
      display: block;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .menu-close {
    width: 100%;
    height: auto;
    .panel {
      width: 100%;
      height: 50px;
      margin-top: 5px;
      position: relative;
      cursor: pointer;
      &:hover .child-content {
        display: block;
      }
      &:hover {
        background: #f8fbff;
      }
      .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
      }
      .child-content {
        position: absolute;
        width: 140px;
        height: auto;
        background: #ffffff;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        left: 60px;
        top: 0;
        display: none;
        z-index: 999;
      }
      .nav-close-title {
        width: 100%;
        height: 48px;
        border-bottom: 1px solid #f2f7ff;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 48px;
      }
      .sub-menu-item {
        width: 100%;
        height: 19px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 19px;
        padding-top: 16px;
        cursor: pointer;
        &:last-child {
          padding-bottom: 16px;
        }
        &:hover {
          color: #2395f3;
        }
      }
      .active {
        color: #2395f3;
      }
    }
  }
}
.nav-content:not(.isCollapse) {
  text-align: left;
  width: 100%;
  .nav-title {
    margin-left: 5px;
    display: inline;
  }
}
</style>
