import asyncRoutes from '@/router/menuLists'
import { treeToList } from '@/utils/common'
import config from '@/utils/config'
const ADD_MENU = 'ADD_MENU'
const ADD_ROUTER = 'ADD_ROUTER'
const COMPLETE = 'COMPLETE'
const ADD_INITROUTER = 'ADD_INITROUTER'
const CHANGE_COMPLETE = 'CHANGE_COMPLETE'
const menu = {
  state: {
    menuLists: [],
    addRouters: [],
    initRouter: [],
    initRouterComplate: false,
    isSuccess: false
  },
  mutations: {
    [ADD_MENU](state, list) {
      state.menuLists = list
    },
    [ADD_ROUTER](state, list) {
      state.addRouters = list
    },
    [COMPLETE](state, bool) {
      state.isSuccess = bool
    },
    [ADD_INITROUTER](state, list) {
      state.initRouter = list
    },
    [CHANGE_COMPLETE](state, bool) {
      state.initRouterComplate = bool
    }
  },
  actions: {
    addRouter({ commit, state }) {
      console.log('asyncRoutes', asyncRoutes, state)
      return new Promise((resolve) => {
        let initRouter = state.initRouter
        initRouter = treeToList(initRouter, 'children')
        const addRoutes = []
        let tempList = []
        let tempChildList = []
        // 这里只做了两层的路由处理
        asyncRoutes.forEach((route) => {
          const item = initRouter.filter((a) => {
            return a.code === route.meta.code
          })
          if (item && item.length > 0) {
            tempList = []
            if (route.children) {
              route.children.forEach((b) => {
                const itemChild = initRouter.filter((a) => {
                  return a.code === b.meta.code
                })
                if (!b.children) {
                  if (itemChild && itemChild.length > 0) {
                    tempList.push(b)
                  }
                } else {
                  tempChildList = []
                  b.children.forEach(c => {
                    const itemChild2 = initRouter.filter((a) => {
                      return a.code === c.meta.code
                    })
                    if (itemChild2 && itemChild2.length > 0) {
                      tempChildList.push(c)
                    }
                  })
                  if (tempChildList.length > 0) {
                    tempList.push(
                      Object.assign({}, b, {
                        children: tempChildList
                      })
                    )
                  }
                }
              })
              if (tempList.length > 0) {
                addRoutes.push(
                  Object.assign({}, route, {
                    children: tempList
                  })
                )
              }
            } else {
              addRoutes.push(route)
            }
          }
        })
        commit(ADD_ROUTER, addRoutes)
        commit(ADD_MENU, addRoutes)
        commit(CHANGE_COMPLETE, true)
        resolve()
      })
    }
  },
  getters: {
    menuLists: (state) => {
      const { menuLists } = state
      const arr = []
      menuLists.forEach((item) => {
        if (
          item.meta.code !== config.menuCode.PDF &&
          item.meta.code !== config.menuCode.YLWZ
        ) {
          arr.push(item)
        }
      })
      return arr
    },
    initRouter: (state) => state.initRouter,
    addRouters: (state) => state.addRouters,
    isSuccess: (state) => state.isSuccess,
    initRouterComplate: (state) => state.initRouterComplate
  }
}
export default menu
