<template>
  <div class="header-content">
    <div class="logo">
      <img
        class="omsLogo"
        src="@/assets/images/loyout/logo1.png"
        width="134px"
        height="23px"
      />
      <div class="line"></div>
      <img
        class="ccsLogo"
        src="@/assets/images/loyout/logo2.png"
        width="44px"
        height="22px"
      />
    </div>
    <div class="opt">
      <!-- <el-input v-model="search" type="text" placeholder="请输入搜索内容">
      </el-input> -->
      <!-- <el-button class="btn" icon="el-icon-search"></el-button> -->
      <div style="display: flex">
        <div class="item" title="查看使用文档" @click="doc">
          <img src="@/assets/images/loyout/doc.png" />
        </div>
        <div class="item" title="待办" @click="showUpComing">
          <span v-if="isNewUpcome" class="new-info"></span>
          <img src="@/assets/images/loyout/upcoming.png" />
        </div>
        <div class="item" title="消息" @click="showMessage">
          <span v-if="isNewMeg" class="new-info"></span>
          <img src="@/assets/images/loyout/message.png" />
        </div>
        <el-popover
          popper-class="userPop"
          placement="bottom-end"
          :offset="20"
          trigger="hover"
        >
          <div
            class="user-menu"
            @mouseenter="topArrow = true"
            @mouseleave="topArrow = false"
          >
            <div class="user-header">
              <div class="left flex_c_c"><img src="@/assets/images/loyout/portrait.png"></div>
              <div class="right">
                <div class="name">{{ userInfo?.userName }}</div>
                <div class="label">
                  <span
                    v-for="item in userInfo?.userRoleName"
                    :key="item"
                    class="role flex_c_c"
                  >
                    {{ item }}
                  </span>
                  <span class="type flex_c_c">{{ userInfo?.type }}</span>
                </div>
              </div>
            </div>
            <ul class="user-body">
              <li class="info">
                <span>编码</span><span>{{ userInfo?.code }}</span>
              </li>
              <li class="info">
                <span>电话</span><span>{{ userInfo?.phone }}</span>
              </li>
              <li class="info">
                <span>邮箱</span><span>{{ userInfo?.email }}</span>
              </li>
              <li class="info"><span>地点</span><span>{{ country }}</span></li>
              <li class="info">
                <span>状态</span
                ><span
                ><i class="mark" :class="[userInfo?.isActived ? 'green' : 'red']"></i
                >{{ userInfo?.isActived ? "使用中" : "停用" }}</span
                >
              </li>
            </ul>
            <div class="user-footer" @click="exit">退出登录</div>
          </div>
          <div
            slot="reference"
            class="user"
            @mouseenter="enterUser"
            @mouseleave="leaveUser"
          >
            <div class="avatar">
              <img src="@/assets/images/loyout/avatar.png">
            </div>
            <i class="el-icon-caret-bottom icon" :class="{ deg: topArrow }" />
          </div>
        </el-popover>
      </div>
    </div>
    <upcome-drawer></upcome-drawer>
    <message-drawer></message-drawer>
  </div>
</template>

<script>
import MessageDrawer from './MessageDrawer.vue'
import UpcomeDrawer from './UpcomeDrawer.vue'
import { logout } from '@/api/system'
import { mapGetters } from 'vuex'
export default {
  components: { UpcomeDrawer, MessageDrawer },
  data() {
    return {
      search: '',
      topArrow: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'addressDropOption', 'isNewMeg', 'isNewUpcome']),
    country() {
      const label = this.addressDropOption?.find(a => a.value === this.userInfo?.countryId)?.label
      return label
    }
  },
  mounted() {
    if (!this.userInfo) {
      this.$store.dispatch('getUserInfo')
    }
    if (!this.addressDropOption) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    showUpComing() {
      this.$store.commit('SET_ISNEWUPCOME', false)
      this.$store.commit('CHANGE_UPCOME_DRAWER', true)
    },
    showMessage() {
      this.$store.commit('SET_ISNEWMSG', false)
      this.$store.commit('CHANGE_MESSAGE_DRAWER', true)
    },
    doc() {
      const { doc } = this.$route.meta
      window.open(`https://ccs.feepower.com/doc/basic/${doc}`)
    },
    enterUser() {
      this.topArrow = true
    },
    leaveUser() {
      this.topArrow = false
    },
    async exit() {
      const res = await logout()
      if (res.success) {
        this.$store.commit('USER_SET', '')
        this.$store.commit('ADD_INITROUTER', [])
        this.$store.commit('RESET_TABSVIEW', [])
        sessionStorage.removeItem('userInfo')
        this.$router.push('/')
      } else {
        this.$message.error(res.errorMessage)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .logo {
    display: flex;
    align-items: center;
    .omsLogo {
      margin-left: 20px;
    }
    .line {
      width: 0px;
      height: 16px;
      opacity: 1;
      border: 1px solid #dae5ff;
      margin-left: 15px;
    }
    .ccsLogo {
      margin-left: 15px;
    }
  }
  .opt {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .item {
      width: 30px;
      height: 30px;
      background: #5bb5ff;
      margin-top: 5px;
      border-radius: 15px 15px 15px 15px;
      cursor: pointer;
      padding: 5px;
      box-sizing: border-box;
      position: relative;
      &:first-child {
        margin-left: 20px;
      }
      & + .item {
        margin-left: 10px;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .new-info {
        width: 12px;
        height: 12px;
        background: #FE453D;
        border-radius: 50%;
        opacity: 1;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 16px;
      }
    }
    .user {
      display: flex;
      justify-content: space-between;
      width: 52px;
      margin-left: 10px;
      cursor: pointer;
    }
    :deep(.el-input__inner) {
      height: 30px;
      line-height: 30px;
      border-radius: 5px 0 0 5px;
    }
    .btn {
      padding: 4px 12px;
      height: 30px;
      border-radius: 0px 5px 5px 0px;
    }
    .avatar {
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      margin-top: 5px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .icon {
      line-height: 40px;
      color: #fff;
      transition: all .5s;
    }
    .deg {
      transform: rotate(180deg);
    }
  }
}
</style>
<style>
.userPop {
  top: 30px !important;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 6px 6px 6px 6px;
  padding: 0;
}
</style>
