import http from './http'
import config from '@/utils/config'
//
/**
 *  @parms resquest 请求地址
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

const mbl = `/backEnd/api/${config.supplyCode}/MBL`

function getMBLPriorityList(params) {
  return http.post(`${mbl}/getMBLPriorityList`, params)
}
function saveMBLPriority(params) {
  return http.post(`${mbl}/saveMBLPriority`, params)
}
function getMBLFollowUpTerminologyList(params) {
  return http.post(`${mbl}/getMBLFollowUpTerminologyList`, params)
}
function getMBLFollowUpTerminology(params) {
  return http.get(`${mbl}/getMBLFollowUpTerminology`, params)
}
function saveMBLFollowUpTerminology(params) {
  return http.post(`${mbl}/saveMBLFollowUpTerminology`, params)
}
function switchMBLFollowUpTerminology(params) {
  return http.post(`${mbl}/switchMBLFollowUpTerminology`, params)
}
function switchMBLPriority(params) {
  return http.get(`${mbl}/switchMBLPriority`, params)
}
function confirmMBL(params) {
  return http.post(`${mbl}/confirmMBL`, params)
}
function refuseMBL(params) {
  return http.post(`${mbl}/refuseMBL`, params)
}
function getMBLPageList(params) {
  return http.post(`${mbl}/getMBLPageList`, params)
}
function getMBLFollowUpList(params) {
  return http.post(`${mbl}/getMBLFollowUpList`, params)
}
function confirmMBLInfo(params) {
  return http.get(`${mbl}/confirmMBLInfo`, params)
}
function getMBLDetail(params) {
  return http.get(`${mbl}/getMBLDetail`, params)
}
function getMBLProduct(params) {
  return http.get(`${mbl}/getMBLProduct`, params)
}
function saveMBLProduct(params) {
  return http.post(`${mbl}/saveMBLProduct`, params)
}
function forecastAccepted(params) {
  return http.post(`${mbl}/forecastAccepted`, params)
}
function addMBLFollowUp(params) {
  return http.post(`${mbl}/addMBLFollowUp`, params)
}
function getOrderDetailPages(params) {
  return http.post(`${mbl}/getOrderDetailPages`, params)
}
function arrivedAndDischarge(params) {
  return http.post(`${mbl}/arrivedAndDischarge`, params)
}
function getFacilityDetail(params) {
  return http.get(`${mbl}/getFacilityDetail`, params)
}
function notifyCompanyUploadFile(params) {
  return http.get(`${mbl}/notifyCompanyUploadFile`, params)
}
function reservation(params) {
  return http.post(`${mbl}/reservation`, params)
}
function reservationArrived(params) {
  return http.get(`${mbl}/reservationArrived`, params)
}
function arrivedFacility(params) {
  return http.post(`${mbl}/arrivedFacility`, params)
}
function departruedFacility(params) {
  return http.post(`${mbl}/departruedFacility`, params)
}
function mblFinished(params) {
  return http.get(`${mbl}/mblFinished`, params)
}
function getMBLDDL(params) {
  return http.get(`${mbl}/getMBLDDL`, params)
}
function existsMBL(params) {
  return http.get(`${mbl}/existsMBL`, params)
}
function getMBLSendWay(params) {
  return http.get(`${mbl}/getMBLSendWay`, params)
}
function getTruckPages(params) {
  return http.post(`${mbl}/getTruckPages`, params)
}
function markNormal(params) {
  return http.post(`${mbl}/markNormal`, params)
}
function markException(params) {
  return http.post(`${mbl}/markException`, params)
}
function truckDetail(params) {
  return http.get(`${mbl}/truckDetail`, params)
}
function uploadPOD(params) {
  return http.upload(`${mbl}/uploadPOD`, params)
}
function uploadISA(params) {
  return http.upload(`${mbl}/uploadISA`, params)
}
function uploadBOL(params) {
  return http.upload(`${mbl}/uploadBOL`, params)
}
function getTruckInfo(params) {
  return http.get(`${mbl}/getTruckInfo`, params)
}
function addTruckSurcharge(params) {
  return http.post(`${mbl}/addTruckSurcharge`, params)
}
function exportTruckExcel(params) {
  return http.download(`${mbl}/exportTruckExcel`, params, 'post')
}
function getMBLTrackList(params) {
  return http.get(`${mbl}/getMBLTrackList`, params)
}
function airMBLArrived(params) {
  return http.get(`${mbl}/airMBLArrived`, params)
}
function GGTGetSCINList(params) {
  return http.get(`${mbl}/GGTGetSCINList`, params)
}

function GetDoTemplate(params) {
  return http.get(`${mbl}/GetDoTemplate`, params)
}
function ChangeDoTemplate(params) {
  return http.post(`${mbl}/ChangeDoTemplate`, params)
}
function PushMBLToGGT(params) {
  return http.post(`${mbl}/PushMBLToGGT`, params)
}
function GGTChangeSCIN(params) {
  return http.post(`${mbl}/GGTChangeSCIN`, params)
}
function DeleteDoTemplate(params) {
  return http.get(`${mbl}/DeleteDoTemplate`, params)
}
function MBLHoldDetail(params) {
  return http.get(`${mbl}/MBLHoldDetail`, params)
}
function getHoldReasonPages(params) {
  return http.post(`${mbl}/getHoldReasonPages`, params)
}
function addHoldReason(params) {
  return http.post(`${mbl}/addHoldReason`, params)
}
function updateHoldReason(params) {
  return http.post(`${mbl}/updateHoldReason`, params)
}
function deleteHoldReason(params) {
  return http.delete(`${mbl}/deleteHoldReason`, params)
}
function getHoldReasonDropdown(params) {
  return http.get(`${mbl}/getHoldReasonDropdown`, params)
}
function holdMBL(params) {
  return http.post(`${mbl}/holdMBL`, params)
}
function realeaseMBL(params) {
  return http.get(`${mbl}/realeaseMBL`, params)
}
function getFMSReqeust(params) {
  return http.get(`${mbl}/getFMSReqeust`, params)
}
function fmsMBLFollowUpList(params) {
  return http.get(`${mbl}/fmsMBLFollowUpList`, params)
}
function pushToFMS(params, id) {
  return http.post(`${mbl}/pushToFMS?mblId=${id}`, params)
}
function addFMSMBLFollowUp(params) {
  return http.post(`${mbl}/addFMSMBLFollowUp`, params)
}
function onLoadMBL(params) {
  return http.post(`${mbl}/onLoadMBL`, params)
}
function inspectionSiteList(params) {
  return http.get(`${mbl}/inspectionSiteList`, params)
}
function saveInspectionSite(params) {
  return http.post(`${mbl}/saveInspectionSite`, params)
}
function getMBLPriceTemplate(params) {
  return http.post(`${mbl}/getMBLPriceTemplate`, params)
}
function addMBLPriceTemplate(params) {
  return http.post(`${mbl}/addMBLPriceTemplate`, params)
}
function updateMBLPriceTemplate(params) {
  return http.post(`${mbl}/updateMBLPriceTemplate`, params)
}
function getMBLPriceTemplateDetail(params) {
  return http.get(`${mbl}/getMBLPriceTemplateDetail`, params)
}
function mblHoldRecordPages(params) {
  return http.post(`${mbl}/mblHoldRecordPages`, params)
}
function mblHoldRecordSetInspec(params) {
  return http.post(`${mbl}/mblHoldRecordSetInspec`, params)
}
function mblHoldRecordSetStatus(params) {
  return http.post(`${mbl}/mblHoldRecordSetStatus`, params)
}
function mblHoldRecordSetBgLabel(params) {
  return http.post(`${mbl}/mblHoldRecordSetBgLabel`, params)
}
function mblHoldRecordSetHoldInfo(params) {
  return http.post(`${mbl}/mblHoldRecordSetHoldInfo`, params)
}
function forecastRefuse(params) {
  return http.get(`${mbl}/forecastRefuse`, params)
}
function searchOnLoadMBLT6(params) {
  return http.get(`${mbl}/searchOnLoadMBLT6`, params)
}

const ISF = `/backEnd/api/${config.supplyCode}/ISF`

function getISFPageList(params) {
  return http.post(`${ISF}/getISFPageList`, params)
}
function getDetail(params) {
  return http.get(`${ISF}/getDetail`, params)
}
function getStatusEvents(params) {
  return http.get(`${ISF}/getStatusEvents`, params)
}
function create(params) {
  return http.post(`${ISF}/create`, params)
}
function isfMainifestSave(params) {
  return http.post(`${ISF}/isfMainifestSave`, params)
}
function GetImporterStatistics(params) {
  return http.post(`${ISF}/GetImporterStatistics`, params)
}
function synchronousISF(shipmentFileNumber, params) {
  return http.post(`${ISF}/synchronousISF?shipmentFileNumber=${shipmentFileNumber}`, params)
}
function cancelISF(shipmentFileNumber, params) {
  return http.post(`${ISF}/cancelISF?shipmentFileNumber=${shipmentFileNumber}`, params)
}

export {
  getMBLPriorityList,
  saveMBLPriority,
  getMBLFollowUpTerminology,
  getMBLFollowUpTerminologyList,
  saveMBLFollowUpTerminology,
  switchMBLFollowUpTerminology,
  switchMBLPriority,
  getISFPageList,
  getDetail,
  getStatusEvents,
  create,
  confirmMBL,
  getMBLPageList,
  getMBLFollowUpList,
  confirmMBLInfo,
  refuseMBL,
  forecastRefuse,
  getMBLDetail,
  getMBLProduct,
  saveMBLProduct,
  forecastAccepted,
  addMBLFollowUp,
  getOrderDetailPages,
  arrivedAndDischarge,
  getFacilityDetail,
  notifyCompanyUploadFile,
  reservation,
  reservationArrived,
  arrivedFacility,
  departruedFacility,
  mblFinished,
  getMBLDDL,
  existsMBL,
  getMBLSendWay,
  getTruckPages,
  markNormal,
  markException,
  truckDetail,
  uploadPOD,
  uploadISA,
  uploadBOL,
  getTruckInfo,
  addTruckSurcharge,
  getMBLTrackList,
  airMBLArrived,
  synchronousISF,
  cancelISF,
  isfMainifestSave,
  exportTruckExcel,
  GGTGetSCINList,
  GetDoTemplate,
  ChangeDoTemplate,
  PushMBLToGGT,
  GGTChangeSCIN,
  DeleteDoTemplate,
  MBLHoldDetail,
  getHoldReasonPages,
  updateHoldReason,
  addHoldReason,
  deleteHoldReason,
  getHoldReasonDropdown,
  holdMBL,
  realeaseMBL,
  getFMSReqeust,
  fmsMBLFollowUpList,
  pushToFMS,
  addFMSMBLFollowUp,
  onLoadMBL,
  inspectionSiteList,
  getMBLPriceTemplate,
  addMBLPriceTemplate,
  updateMBLPriceTemplate,
  getMBLPriceTemplateDetail,
  mblHoldRecordPages,
  mblHoldRecordSetInspec,
  mblHoldRecordSetStatus,
  mblHoldRecordSetBgLabel,
  GetImporterStatistics,
  saveInspectionSite,
  mblHoldRecordSetHoldInfo,
  searchOnLoadMBLT6
}
