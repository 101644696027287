// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/loginbg.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/development.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".container[data-v-6d385c0f] {\n  width: 100%;\n  height: 100%;\n  background: #F8FBFF;\n  position: relative;\n  display: flex;\n  align-items: center;\n  flex-direction: row-reverse;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;\n  background-size: cover;\n}\n.logobg[data-v-6d385c0f] {\n  position: absolute;\n  top: 40px;\n  left: 64px;\n  width: 356px;\n  height: 41px;\n}\n.form[data-v-6d385c0f] {\n  position: relative;\n  width: 500px;\n  height: 600px;\n  background: #FFFFFF;\n  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);\n  border-radius: 6px 6px 6px 6px;\n  opacity: 1;\n  margin-right: 220px;\n}\n.showIcon[data-v-6d385c0f]::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 75px;\n  width: 75px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 75px;\n}\n.copyright[data-v-6d385c0f] {\n  position: absolute;\n  left: 50%;\n  bottom: 24px;\n  transform: translateX(-50%);\n  font-size: 14px;\n  font-family: Microsoft YaHei-Regular, Microsoft YaHei;\n  font-weight: 400;\n  color: #999999;\n  white-space: nowrap;\n}\n.copyright a[data-v-6d385c0f] {\n  color: #999999;\n  text-decoration: none;\n}\n", ""]);
// Exports
module.exports = exports;
