import http from './http'
import config from '@/utils/config'
//
/**
 *  @parms resquest 请求地址
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

const home = `/backEnd/api/${config.supplyCode}/Home`
function workBenchOverview(params) {
  return http.get(`${home}/workBenchOverview`, params)
}
function getWorkOrderPages(params) {
  return http.post(`${home}/getWorkOrderPages`, params)
}
function getMessagePages(params) {
  return http.post(`${home}/getMessagePages`, params)
}
function getSchedulePages(params) {
  return http.post(`${home}/getSchedulePages`, params)
}
function readMessage(params) {
  return http.put(`${home}/readMessage`, params)
}
function completedSchedule(params) {
  return http.put(`${home}/completedSchedule`, params)
}

export {
  workBenchOverview,
  getMessagePages,
  getSchedulePages,
  readMessage,
  completedSchedule,
  getWorkOrderPages
}
