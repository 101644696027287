var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resister-content" },
    [
      _c("Tab", { attrs: { tabData: _vm.tabData, active: _vm.active } }),
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "username" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入用户名（特殊字符不可输入）",
                    },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "username" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "text",
                        placeholder: `${
                          _vm.form.type ? "请输入邮箱" : "请输入手机号"
                        }`,
                      },
                      model: {
                        value: _vm.form.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "number", $$v)
                        },
                        expression: "form.number",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            color: "#25396F",
                            "font-size": "16px",
                          },
                          attrs: { slot: "prepend", placeholder: "请选择" },
                          slot: "prepend",
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "手机号", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "邮箱", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "username" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ecode" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          type: "text",
                          maxlength: "20",
                          placeholder: "请输入验证码",
                        },
                        model: {
                          value: _vm.form.passCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "passCode", $$v)
                          },
                          expression: "form.passCode",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: {
                            "margin-left": "16px",
                            width: "124px",
                          },
                          attrs: { disabled: _vm.disabled },
                          on: { click: _vm.sendECode },
                        },
                        [_vm._v(_vm._s(_vm.sendCode))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { prop: "password" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "password", placeholder: "请输入密码" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticClass: "des" }, [
                _vm._v(
                  " 密码长度6-32个字符，字母/数字/下划线组成(必须含有字母和数字) "
                ),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "registe-btn",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.reigster },
                },
                [_vm._v("注册")]
              ),
              _c("p", { staticClass: "goLogin", on: { click: _vm.goLogin } }, [
                _vm._v("已有账号？返回登录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }