import http from './http'
import config from '@/utils/config'
//
/**
 *  @parms resquest 请求地址
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

const resquest = `/backEnd/api/${config.supplyCode}/auth`

function login(params) {
  return http.post(`${resquest}/login`, params)
}
function logout(params) {
  return http.post(`${resquest}/logout`, params)
}

function validationPhoneEmail(params) {
  return http.post(`${resquest}/validationPhoneEmail`, params)
}

function sendPassCode(params) {
  return http.post(`${resquest}/sendPassCode`, params)
}
function register(params) {
  return http.post(`${resquest}/register`, params)
}
function forgotPassword(params) {
  return http.post(`${resquest}/forgotPassword`, params)
}
function keySign(params) {
  return http.get(`${resquest}/keySign?key=${params}`)
}

const user = `/backEnd/api/${config.supplyCode}/user`

function userInfo() {
  return http.get(`${user}/userInfo`)
}
function addUser(params) {
  return http.post(`${user}/addUser`, params)
}
function updateUser(params) {
  return http.post(`${user}/updateUser`, params)
}
function getUserLogPages(params) {
  return http.post(`${user}/getUserLogPages`, params)
}
function getUserType(params) {
  return http.get(`${user}/getUserType`, params)
}
function getUserForType(params) {
  return http.post(`${user}/getUserForType`, params)
}
function getSalesTargetPages(params) {
  return http.post(`${user}/getSalesTargetPages`, params)
}
function changeSalesTarget(params) {
  return http.post(`${user}/changeSalesTarget`, params)
}
function delSalesTarget(params) {
  return http.get(`${user}/delSalesTarget`, params)
}
function getSaleList(params) {
  return http.get(`${user}/getSaleList`, params)
}

const comm = `/backEnd/api/${config.supplyCode}/Comm`
function getSysParams(params) {
    return http.get(`${comm}/getSysParams`, params)
}
function addParams(params) {
    return http.post(`${comm}/addParams`, params)
}
function updateParams(params) {
    return http.post(`${comm}/updateParams`, params)
}
function deleteParams(params) {
    return http.post(`${comm}/deleteParams`, params)
}

const company = `/backEnd/api/${config.supplyCode}/company`

function getNotApprovedCompanyPages(params) {
  return http.post(`${company}/getNotApprovedCompanyPages`, params)
}
function approve(params) {
  return http.post(`${company}/approve`, params)
}
function getCompanyPages(params) {
  return http.post(`${company}/getCompanyPages`, params)
}
function getCompanyDetail(params) {
  return http.post(`${company}/getCompanyDetail`, params)
}
function updateCompany(params) {
  return http.post(`${company}/updateCompany`, params)
}
function disableCompany(params) {
  return http.put(`${company}/disableCompany`, params)
}
function enableCompany(params) {
  return http.put(`${company}/enableCompany`, params)
}
function getCEA(params) {
  return http.get(`${company}/getCEA`, params)
}
function addCEA(params) {
  return http.post(`${company}/addCEA`, params)
}
function updateCEA(params) {
  return http.post(`${company}/updateCEA`, params)
}
function deleteCEA(params) {
  return http.delete(`${company}/deleteCEA`, params)
}
function getCompanyDrapdown(params) {
  return http.get(`${company}/getCompanyDrapdown`, params)
}
function getExpressAccountCompanyList(params) {
  return http.get(`${company}/getExpressAccountCompanyList`, params)
}
function copyExpressAccountCompany(params) {
  return http.post(`${company}/copyExpressAccountCompany`, params)
}
function reject(params) {
  return http.post(`${company}/reject`, params)
}
function addCompanyExpressAccountTemplate(params) {
  return http.post(`${company}/addCompanyExpressAccountTemplate`, params)
}
function switchCompanyExpressAccount(params, type) {
  return http.post(`${company}/switchCompanyExpressAccount?type=${type}`, params)
}
function getCompanyMBLPriceList(params) {
  return http.get(`${company}/getCompanyMBLPriceList`, params)
}
function getCompanyMBLPriceTemplate(params) {
  return http.get(`${company}/getCompanyMBLPriceTemplate`, params)
}
function bindingMBLPriceTemplate(params) {
  return http.post(`${company}/bindingMBLPriceTemplate`, params)
}
function expireMBLPriceTemplate(params) {
  return http.get(`${company}/expireMBLPriceTemplate`, params)
}

const supply = `/backEnd/api/${config.supplyCode}/Supplier`

function GetSupplierPageList(params) {
  return http.post(`${supply}/GetSupplierPageList`, params)
}
function switchSupplier(params) {
  return http.get(`${supply}/switchSupplier`, params)
}
function getSupplierBankAccount(params) {
  return http.get(`${supply}/getSupplierBankAccount`, params)
}
function insertSupplier(params) {
  return http.post(`${supply}/insertSupplier`, params)
}
function updateSupplier(params) {
  return http.post(`${supply}/updateSupplier`, params)
}
function getSupplierDropDownList(params) {
  return http.post(`${supply}/getSupplierDropDownList`, params)
}

const workOrder = `/backEnd/api/${config.supplyCode}/workOrder`
function deleteWorkOrderItem(params) {
  return http.delete(`${workOrder}/deleteWorkOrderItem`, params)
}
function swichWorkOrderItem(params) {
  return http.get(`${workOrder}/swichWorkOrderItem`, params)
}
function getWorkOrderTypeList(params) {
  return http.get(`${workOrder}/GetWorkOrderTypeList`, params)
}
function insertWorkOrderType(params) {
  return http.post(`${workOrder}/insertWorkOrderType`, params)
}
function insertWorkOrderItem(params) {
  return http.post(`${workOrder}/insertWorkOrderItem`, params)
}
function getWorkOrderItemPageList(params) {
  return http.post(`${workOrder}/GetWorkOrderItemPageList`, params)
}
function getWorkOrderList(params) {
  return http.post(`${workOrder}/getWorkOrderList`, params)
}
function workOrderDetail(params) {
  return http.get(`${workOrder}/workOrderDetail`, params)
}
function addWorkOrderRemark(params) {
  return http.post(`${workOrder}/addWorkOrderRemark`, params)
}

function closeWorkOrder(params) {
  return http.post(`${workOrder}/closeWorkOrder`, params)
}
function passWorkOrder(params) {
  return http.post(`${workOrder}/passWorkOrder`, params)
}
function getWorkOrderItemList(params) {
  return http.get(`${workOrder}/getWorkOrderItemList`, params)
}
function addWorkOrder(params) {
  return http.post(`${workOrder}/addWorkOrder`, params)
}
function transitWorkOrder(params) {
  return http.post(`${workOrder}/transitWorkOrder`, params)
}

const makingSetting = `/backEnd/api/${config.supplyCode}/MakingSetting`
function getExpressSupplierList(params) {
  return http.get(`${makingSetting}/GetExpressSupplierList`, params)
}
function insertExpressSupplier(params) {
  return http.post(`${makingSetting}/insertExpressSupplier`, params)
}
function insertFuelRate(params) {
  return http.post(`${makingSetting}/insertFuelRate`, params)
}
function updateFuelRate(params) {
  return http.post(`${makingSetting}/updateFuelRate`, params)
}
function getFuelRatePageList(params) {
  return http.post(`${makingSetting}/GetFuelRatePageList`, params)
}
function getExpressSupplierThirdPartyList(params) {
  return http.get(`${makingSetting}/getExpressSupplierThirdPartyList`, params)
}
function getExpressSupplierDefaultParamsList(params) {
  return http.post(`${makingSetting}/getExpressSupplierDefaultParamsList`, params)
}
function getExpressAccountPageList(params) {
  return http.post(`${makingSetting}/getExpressAccountPageList`, params)
}
function insertExpressAccount(params) {
  return http.post(`${makingSetting}/insertExpressAccount`, params)
}
function getExpressAccountSetting(params) {
  return http.get(`${makingSetting}/getExpressAccountSetting`, params)
}
function updateExpressAccount(params) {
  return http.post(`${makingSetting}/updateExpressAccount`, params)
}
function getExpressAccountZone(params) {
  return http.get(`${makingSetting}/getExpressAccountZone`, params)
}
function deleteExpressAccountZone(params) {
  return http.get(`${makingSetting}/deleteExpressAccountZone`, params)
}
function copyExpressAccountZone(params) {
  return http.post(`${makingSetting}/copyExpressAccountZone`, params)
}
function insertExpressAccountPriceTemplate(params) {
  return http.post(`${makingSetting}/insertExpressAccountPriceTemplate`, params)
}
function getExpressAccountPriceTemplatePageList(params) {
  return http.post(`${makingSetting}/getExpressAccountPriceTemplatePageList`, params)
}
function getExpressAccountPricePageList(params) {
  return http.post(`${makingSetting}/getExpressAccountPricePageList`, params)
}
function getExpressAccountOtherPriceList(params) {
  return http.get(`${makingSetting}/getExpressAccountOtherPriceList`, params)
}
function deleteExpressAccountPriceTemplate(params) {
  return http.get(`${makingSetting}/deleteExpressAccountPriceTemplate`, params)
}
function getExpressAccountPriceList(params) {
  return http.get(`${makingSetting}/getExpressAccountPriceList`, params)
}
function updateExpressAccountPriceTemplate(params) {
  return http.post(`${makingSetting}/updateExpressAccountPriceTemplate`, params)
}
function getExpressAccountTempaltePage(params) {
  return http.post(`${makingSetting}/getExpressAccountTempaltePage`, params)
}
function insertExpressAccountTemplate(params) {
  return http.post(`${makingSetting}/insertExpressAccountTemplate`, params)
}
function updateExpressAccountTemplate(params) {
  return http.post(`${makingSetting}/updateExpressAccountTemplate`, params)
}
function expressAccountDrapdown(params) {
  return http.get(`${makingSetting}/expressAccountDrapdown`, params)
}
function getExpressAccountTempalteItem(params) {
  return http.get(`${makingSetting}/getExpressAccountTempalteItem`, params)
}
function expressAccountList(params) {
  return http.get(`${makingSetting}/expressAccountList`, params)
}
function expressAccountTemplateDrapdown(params) {
  return http.get(`${makingSetting}/expressAccountTemplateDrapdown`, params)
}
function updateExpressSupplierBalance(params) {
  return http.get(`${makingSetting}/updateExpressSupplierBalance`, params)
}

const file = `/backEnd/api/${config.supplyCode}/file`
function downLoadTemplate(params) {
  return http.download(`${file}/downLoadTemplate?guid=${params}`)
}
function getMBLFile(params) {
  return http.get(`${file}/getMBLFile`, params)
}
function uploadMBLFile(params, formData, fn) {
  params = Object.keys(params).map(a => `${a}=${params[a]}`).join('&')
  return http.upload(`${file}/uploadMBLFile?${params}`, formData, fn)
}
function workOrderFile(params) {
  return http.get(`${file}/workOrderFile`, params)
}
function certification(params) {
  return http.get(`${file}/certification`, params)
}

const systemSetting = `/backEnd/api/${config.supplyCode}/SystemSetting`
function getSystemTab(params) {
  return http.get(`${systemSetting}/getSystemTab`, params)
}
function getSysTableUser(params) {
  return http.get(`${systemSetting}/getSysTableUser`, params)
}
function TableUserAdd(params) {
  return http.post(`${systemSetting}/TableUserAdd`, params)
}

export {
  login,
  logout,
  validationPhoneEmail,
  sendPassCode,
  register,
  keySign,
  userInfo,
  addUser,
  updateUser,
  getUserLogPages,
  getUserType,
  getUserForType,
  getSysParams,
  addParams,
  deleteParams,
  getNotApprovedCompanyPages,
  approve,
  getCompanyPages,
  getCompanyDetail,
  getCompanyDrapdown,
  updateCompany,
  disableCompany,
  enableCompany,
  getCEA,
  addCEA,
  updateCEA,
  deleteCEA,
  GetSupplierPageList,
  getSupplierDropDownList,
  switchSupplier,
  insertSupplier,
  updateSupplier,
  deleteWorkOrderItem,
  getWorkOrderItemList,
  addWorkOrder,
  swichWorkOrderItem,
  getWorkOrderTypeList,
  insertWorkOrderType,
  insertWorkOrderItem,
  transitWorkOrder,
  closeWorkOrder,
  passWorkOrder,
  getWorkOrderItemPageList,
  getWorkOrderList,
  workOrderDetail,
  addWorkOrderRemark,
  workOrderFile,
  getExpressSupplierList,
  insertExpressSupplier,
  insertFuelRate,
  updateFuelRate,
  getFuelRatePageList,
  getExpressSupplierThirdPartyList,
  getExpressSupplierDefaultParamsList,
  getExpressAccountPageList,
  insertExpressAccount,
  getExpressAccountSetting,
  updateExpressAccount,
  getExpressAccountZone,
  deleteExpressAccountZone,
  copyExpressAccountZone,
  insertExpressAccountPriceTemplate,
  getExpressAccountPriceTemplatePageList,
  getExpressAccountPricePageList,
  getExpressAccountOtherPriceList,
  deleteExpressAccountPriceTemplate,
  getExpressAccountPriceList,
  updateExpressAccountPriceTemplate,
  downLoadTemplate,
  getSystemTab,
  getSysTableUser,
  TableUserAdd,
  updateParams,
  getMBLFile,
  forgotPassword,
  getSupplierBankAccount,
  uploadMBLFile,
  getExpressAccountCompanyList,
  expressAccountDrapdown,
  copyExpressAccountCompany,
  switchCompanyExpressAccount,
  getExpressAccountTempaltePage,
  getExpressAccountTempalteItem,
  expressAccountList,
  insertExpressAccountTemplate,
  updateExpressAccountTemplate,
  reject,
  addCompanyExpressAccountTemplate,
  expressAccountTemplateDrapdown,
  certification,
  getSalesTargetPages,
  changeSalesTarget,
  delSalesTarget,
  getCompanyMBLPriceList,
  getCompanyMBLPriceTemplate,
  bindingMBLPriceTemplate,
  expireMBLPriceTemplate,
  updateExpressSupplierBalance,
  getSaleList
}
