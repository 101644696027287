const SET_CONNNET = 'SET_CONNNET'
import { completedSchedule } from '@/api/home'
import { Message } from 'element-ui'

const user = {
  state: {
    connect: {}
  },
  mutations: {
    [SET_CONNNET](state, params) {
      state.connect = params
    }
  },
  actions: {
    setEmptyConnect({ commit }) {
       commit(SET_CONNNET, {})
    },
    readConnnet({ state, commit }) {
        if (state.connect.scheduleId) {
          return new Promise((resolve, reject) => {
            completedSchedule({
              id: state.connect.scheduleId
            })
              .then((res) => {
                if (res.success) {
                  commit(SET_CONNNET, {})
                } else {
                  Message.error(res.errorMessage)
                }
              })
              .catch((err) => {
                return reject(err)
              })
          })
        }
    }
  },
  getters: {
    connect: (state) => state.SET_CONNNET
  }
}
export default user
