var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.$store.state.system.upcomeDrawer,
        "append-to-body": true,
        size: _vm.size,
        modal: false,
        wrapperClosable: true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.$store.state.system, "upcomeDrawer", $event)
        },
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("span", [
          _c(
            "span",
            {
              staticClass: "read",
              class: { active: !_vm.isActive },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick(0)
                },
              },
            },
            [_vm._v("待办(" + _vm._s(_vm.msgData.unread) + ")")]
          ),
          _c(
            "span",
            {
              staticClass: "read",
              class: { active: _vm.isActive },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick(1)
                },
              },
            },
            [_vm._v("已办(" + _vm._s(_vm.msgData.read) + ")")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "view-container" }, [
        _c(
          "div",
          { staticClass: "opt" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "120px" },
                attrs: { placeholder: "请选择", clearable: "" },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              },
              _vm._l(_vm.config.AgencyTypeOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("el-input", {
                      staticClass: "input-search",
                      attrs: { placeholder: "请输入内容搜索" },
                      nativeOn: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.searchVal,
                        callback: function ($$v) {
                          _vm.searchVal = $$v
                        },
                        expression: "searchVal",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "btn-search", on: { click: _vm.search } },
                      [_c("i", { staticClass: "el-icon-search" })]
                    ),
                  ],
                  1
                ),
                _c(
                  "BaseFilter",
                  {
                    attrs: { isActive: _vm.filterActive, width: 452 },
                    on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "filter" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.filterList,
                              "label-width": "56px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: {
                                      placeholder: "请选择类型",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.filterList.companyIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList,
                                          "companyIds",
                                          $$v
                                        )
                                      },
                                      expression: "filterList.companyIds",
                                    },
                                  },
                                  _vm._l(_vm.companyDrapdown, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "优先级" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList.priority,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList,
                                          "priority",
                                          $$v
                                        )
                                      },
                                      expression: "filterList.priority",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "非常紧急", value: "0" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "紧急", value: "1" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "一般", value: "2" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "contanier scrollbar",
          },
          _vm._l(_vm.lists, function (item) {
            return _c("div", { key: item.id, staticClass: "panel" }, [
              _c(
                "div",
                { staticClass: "lable" },
                [
                  _c("p", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/loyout/user.png"),
                      },
                    }),
                    _vm._v(
                      " 客户：" +
                        _vm._s(_vm._f("filterCompany")(item.companyId)) +
                        " "
                    ),
                    _c("span", { staticClass: "msg-type" }, [
                      _vm._v(_vm._s(item.statusName)),
                    ]),
                  ]),
                  !_vm.isActive
                    ? _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openUrl(item)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "lable des" }, [
                _vm._v(" " + _vm._s(item.description) + " "),
              ]),
              _c("div", { staticClass: "lable" }, [
                _vm._v(
                  "创建：" +
                    _vm._s(
                      item.createdOn
                        ? item.createdOn.slice(0, item.createdOn.length - 3)
                        : ""
                    )
                ),
              ]),
              _c(
                "div",
                { staticClass: "level", class: `level-${item.priority}` },
                [_vm._v(" " + _vm._s(item.priority) + " ")]
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "pager" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.pagerConfig.pageIndex,
                "page-size": 20,
                layout: "total, prev, pager, next",
                total: _vm.total,
              },
              on: { "current-change": _vm.handleCurrentChange },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }