import http from './http'
const warehouseApi = '/basic/api/Address'

function getAddressDropdown() {
  return http.post(`${warehouseApi}/getAddressDropdown`)
}

function getImporterPages(params) {
  return http.post(`${warehouseApi}/getImporterPages`, params)
}
function defaultAddressBook(params) {
  return http.post(`${warehouseApi}/defaultAddressBook`, params)
}
function saveAddressBook(params) {
  return http.post(`${warehouseApi}/saveAddressBook`, params)
}
function getAddressBookDrapdown(params) {
  return http.get(`${warehouseApi}/getAddressBookDrapdown`, params)
}
function getAddressFill(params) {
  return http.get(`${warehouseApi}/getAddressFill`, params)
}
function deleteAddressBook(params) {
  return http.delete(`${warehouseApi}/deleteAddressBook`, params)
}
function addressBookCompanyList(params) {
  return http.get(`${warehouseApi}/addressBookCompanyList`, params)
}
function distributeAddressBook(params) {
  return http.post(`${warehouseApi}/distributeAddressBook`, params)
}
function updateImporterAdvance(params) {
  return http.post(`${warehouseApi}/updateImporterAdvance`, params)
}
function getImporterFiles(params) {
  return http.get(`${warehouseApi}/getImporterFiles`, params)
}
function openSearchAddressBooks(params) {
  return http.post(`${warehouseApi}/openSearchAddressBooks`, params)
}

function uploadImporterFile(params, formData, fn) {
  params = Object.keys(params).map(a => `${a}=${params[a]}`).join('&')
  return http.upload(`${warehouseApi}/uploadImporterFile?${params}`, formData, fn)
}

const Tax = '/basic/api/Tax'
function getTaxRatePage(params) {
  return http.post(`${Tax}/getTaxRatePage`, params)
}
function saveTaxRate(params) {
  return http.post(`${Tax}/saveTaxRate`, params)
}
function getTaxProductPage(params) {
  return http.post(`${Tax}/getTaxProductPage`, params)
}
function saveTaxProduct(params) {
  return http.post(`${Tax}/saveTaxProduct`, params)
}
function deleteTaxRate(params) {
  return http.delete(`${Tax}/deleteTaxRate`, params)
}
function deleteTaxProduct(params) {
  return http.delete(`${Tax}/deleteTaxProduct`, params)
}

function copyPrivateProductToPublic(params) {
  return http.get(`${Tax}/copyPrivateProductToPublic`, params)
}

function getTaxRateList(params) {
  return http.get(`${Tax}/getTaxRateList`, params)
}

const Track = '/basic/api/Track'
function trackEvents(params) {
  return http.get(`${Track}/trackEvents`, params)
}
function isfEvents(params) {
  return http.get(`${Track}/isfEvents`, params)
}
function containerEvents(params) {
  return http.get(`${Track}/containerEvents`, params)
}
function AMSQuery(params, update) {
  return http.post(`${Track}/AMSQuery${update ? '?upload=true' : ''}`, params)
}

const Harbor = '/basic/api/Harbor'
function harborDrapDown(params) {
  return http.get(`${Harbor}/harborDrapDown`, params)
}

const transport = '/basic/api/Transport'
function transportDrapDown(params) {
  return http.get(`${transport}/transportDrapDown`, params)
}

// function getTaxProductPage(params) {
//   return http.post(`${Tax}/getTaxProductPage`, params)
// }

// function getTaxRatePage(params) {
//   return http.post(`${Tax}/getTaxRatePage`, params)
// }

// function saveTaxProduct(params) {
//   return http.post(`${Tax}/saveTaxProduct`, params)
// }

// function saveTaxRate(params) {
//   return http.post(`${Tax}/saveTaxRate`, params)
// }
export {
    getAddressDropdown,
    getAddressFill,
    getAddressBookDrapdown,
    getTaxRatePage,
    saveTaxRate,
    getTaxProductPage,
    saveTaxProduct,
    deleteTaxRate,
    deleteTaxProduct,
    copyPrivateProductToPublic,
    getTaxRateList,
    containerEvents,
    AMSQuery,
    isfEvents,
    trackEvents,
    defaultAddressBook,
    deleteAddressBook,
    getImporterPages,
    addressBookCompanyList,
    distributeAddressBook,
    updateImporterAdvance,
    uploadImporterFile,
    getImporterFiles,
    openSearchAddressBooks,
    harborDrapDown,
    saveAddressBook,
    transportDrapDown
}
